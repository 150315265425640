import {useState} from "react";
import {useLocation, useNavigate} from "react-router";
import labels from "../../../languages/languages";
import {useSelector} from "react-redux";
import Icon from "react-web-vector-icons";

const Breadcrumbs = ({theme}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const customization = useSelector(state => state.customization);
  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  function renderText(key) {
    let value = labels.path[key];
    let label = value.find(l => l.language === selectedLanguage);
    return label.label;
  }

  function translatePart(part) {
    if (part === "") {
      return renderText("home");
    } else if (part === "app-sections") {
      return localStorage.getItem("appTextName");
    } else if (part === "notifications") {
      return renderText("notifications");
    } else if (part === "app-management") {
      return renderText("appManagement");
    } else if (part === "chat") {
      return renderText("chat");
    } else if (part === "care") {
      return renderText("care");
    } else if (part === "screens") {
      return renderText("screens");
    } else if (part === "app-settings") {
      return renderText("appSettings");
    } else if (part === "app-extras") {
      return renderText("appExtras");
    } else if (part === "app-users") {
      return renderText("appUsers");
    } else if (part === "app-statistics") {
      return renderText("appStatistics");
    } else if (part === "notifications-push") {
      return renderText("notificationsPush");
    } else if (part === "notifications-geolocation-push") {
      return renderText("notificationsGeolocationPush");
    } else if (part === "notifications-email") {
      return renderText("notificationsEmail");
    } else if (part === "notifications-sms") {
      return renderText("notificationsSms");
    } else if (part === "notification-push-send") {
      return renderText("notificationsPushSend");
    } else if (part === "notification-history-push") {
      return renderText("notificationsPushHistory");
    } else if (part === "notification-scheduled-push") {
      return renderText("notificationsPushScheduled");
    } else if (part === "notification-geolocation-push-send") {
      return renderText("notificationsGeolocationPushSend");
    } else if (part === "notifications-geolocation-history") {
      return renderText("notificationsGeolocationPushHistory");
    } else if (part === "notification-scheduled-geolocation-push") {
      return renderText("notificationsGeolocationPushScheduled");
    } else if (part === "notifications-geolocation-push-zones") {
      return renderText("notificationsGeolocationPushZones");
    } else if (part === "notifications-geolocation-push-zone") {
      return renderText("notificationsGeolocationPushZone");
    } else if (part === "notifications-geolocation-push-new-zone") {
      return renderText("notificationsGeolocationPushNewZone");
    } else if (part === "notification-email-send") {
      return renderText("notificationsEmailSend");
    } else if (part === "notification-history-email") {
      return renderText("notificationsEmailHistory");
    } else if (part === "notification-scheduled-email") {
      return renderText("notificationsEmailScheduled");
    } else if (part === "notification-sms-send") {
      return renderText("notificationsSmsSend");
    } else if (part === "notification-history-sms") {
      return renderText("notificationsSmsHistory");
    } else if (part === "notification-scheduled-sms") {
      return renderText("notificationsSmsScheduled");
    } else if (part === "forms") {
      return renderText("forms");
    } else if (part === "form-results") {
      return renderText("formResults");
    } else if (part === "form-result") {
      return renderText("formResult");
    } else if (part === "quizzes") {
      return renderText("quizzes");
    } else if (part === "new-quiz") {
      return renderText("newQuiz");
    } else if (part === "quiz") {
      return renderText("quiz");
    } else if (part === "quiz-results") {
      return renderText("quizResults");
    } else if (part === "quiz-result") {
      return renderText("quizResult");
    } else if (part === "leaflets") {
      return renderText("leaflets");
    } else if (part === "new-leaflet") {
      return renderText("newLeaflet");
    } else if (part === "leaflet") {
      return renderText("leaflet");
    } else if (part === "app-maps2") {
      return renderText("maps");
    } else if (part === "new-map2") {
      return renderText("newMap");
    } else if (part === "map2") {
      return renderText("map");
    } else if (part === "map-edit") {
      return renderText("mapEdit");
    } else if (part === "chat-rooms") {
      return renderText("chatRooms");
    } else if (part === "chat-room") {
      return renderText("chatRoom");
    } else if (part === "chat-settings") {
      return renderText("chatSettings");
    } else if (part === "new-screen") {
      return renderText("newScreen");
    } else if (part === "screen-settings") {
      return renderText("screenSettings");
    } else if (part === "screen-basic-parameters") {
      return renderText("screenBasicParameters");
    } else if (part === "screen-drawer") {
      return renderText("screenDrawer");
    } else if (part === "screen-tabs") {
      return renderText("screenTabs");
    } else if (part === "screen-background-image") {
      return renderText("screenBackgroundImage");
    } else if (part === "screen-header-image") {
      return renderText("screenHeaderImage");
    } else if (part === "screen-editor") {
      return renderText("screenEditor");
    } else if (part === "app-basic-parameters") {
      return renderText("appBasicParameters");
    } else if (part === "app-studio-settings") {
      return renderText("appStudioSettings");
    } else if (part === "app-languages") {
      return renderText("appLanguages");
    } else if (part === "app-permissions") {
      return renderText("appPermissions");
    } else if (part === "app-icon") {
      return renderText("appIcon");
    } else if (part === "app-splash") {
      return renderText("appSplash");
    } else if (part === "app-notifications") {
      return renderText("appNotifications");
    } else if (part === "welcome") {
      return renderText("welcome");
    } else if (part === "app-system-messages") {
      return renderText("appSystemMessages");
    } else if (part === "app-webview") {
      return renderText("appWebview");
    } else if (part === "app-login") {
      return renderText("appLogin");
    } else if (part === "app-user-notifications") {
      return renderText("appUserNotifications");
    } else if (part === "app-coupon") {
      return renderText("appCoupon");
    } else if (part === "app-search") {
      return renderText("appSearch");
    } else if (part === "app-paid") {
      return renderText("appPaid");
    } else if (part === "app-admin") {
      return renderText("appAdmin");
    } else if (part === "app-ota") {
      return renderText("appOta");
    } else if (part === "mindfulness-list") {
      return renderText("mindfulnessList");
    } else if (part === "new-mindfulness") {
      return renderText("newMindfulness");
    } else if (part === "mindfulness") {
      return renderText("mindfulness");
    } else if (part === "mindfulness-pro-list") {
      return renderText("mindfulnessProList");
    } else if (part === "new-mindfulness-pro") {
      return renderText("newMindfulnessPro");
    } else if (part === "mindfulness-pro") {
      return renderText("mindfulnessPro");
    } else if (part === "mindfulness-touch-list") {
      return renderText("mindfulnessTouchList");
    } else if (part === "new-mindfulness-touch") {
      return renderText("newMindfulnessTouch");
    } else if (part === "mindfulness-touch") {
      return renderText("mindfulnessTouch");
    } else if (part === "app-new-user") {
      return renderText("appNewUser");
    } else if (part === "app-user") {
      return renderText("appUser");
    } else if (part === "statistics-sms") {
      return renderText("statisticsSms");
    } else if (part === "statistics-email") {
      return renderText("statisticsEmail");
    } else if (part === "statistics-app-download") {
      return renderText("statisticsAppDownload");
    } else if (part === "statistics-app-usage") {
      return renderText("statisticsAppUsage");
    } else if (part === "statistics-screens-usage") {
      return renderText("statisticsScreensUsage");
    } else if (part === "statistics-components-usage") {
      return renderText("statisticsComponentsUsage");
    } else if (part === "statistics-users") {
      return renderText("statisticsUsers");
    } else if (part === "statistics-notifications") {
      return renderText("statisticsNotifications");
    } else if (part === "app-user-list") {
      return renderText("appUserList");
    } else if (part === "users") {
      return renderText("users");
    } else if (part === "user") {
      return renderText("user");
    } else if (part === "new-user") {
      return renderText("newUser");
    } else if (part === "smart-notification-push") {
      return renderText("notificationsPushSend");
    } else if (part === "smart-notification-email") {
      return renderText("notificationsEmailSend");
    } else if (part === "smart-notification-sms") {
      return renderText("notificationsSmsSend");
    } else if (part === "smart-screens") {
      return renderText("screens");
    } else if (part === "smart-maps") {
      return renderText("maps");
    } else if (part === "smart-map") {
      return renderText("map");
    } else if (part === "subjects") {
      return renderText("subjects");
    } else if (part === "profile") {
      return renderText("user");
    } else if (part === "paros") {
      return renderText("paros");
    } else if (part === "new-paro") {
      return renderText("newParo");
    } else if (part === "paro") {
      return renderText("paro");
    } else if (part === "paro-screen") {
      return renderText("paroScreen");
    } else if (part === "places-coupons") {
      return renderText("placesCoupons");
    } else if (part === "place-coupons") {
      return renderText("placeCoupons");
    } else if (part === "app-liked-marker") {
      return renderText("appLikedMarker");
    } else if (part === "smart-map") {
      return renderText("map");
    } else if (part === "notifications-registered") {
      return renderText("notificationsRegistered");
    } else if (part === "notifications-registered-send") {
      return renderText("notificationsRegisteredSend");
    } else if (part === "notifications-registered-streets") {
      return renderText("notificationsRegisteredStreets");
    } else if (part === "notifications-registered-users") {
      return renderText("notificationsRegisteredUsers");
    } else if (part === "new-app") {
      return renderText("newApp");
    } else if (part === "accommodation") {
      return renderText("accomodation");
    } else if (part === "owner") {
      return renderText("owner");
    } else if (part === "smart-care") {
      return renderText("care");
    } else if (part === "smart-coupons") {
      return renderText("smartCoupons");
    } else if (part === "coupons") {
      return renderText("coupons");
    } else if (part === "coupon") {
      return renderText("coupon");
    } else if (part === "templates") {
      return renderText("templates");
    } else if (part === "app-eshop") {
      return renderText("eshop");
    } else if (part === "coupon-admin") {
      return renderText("couponAdmin");
    } else if (part === "coupon-list") {
      return renderText("couponList");
    } else if (part === "coupon-editor") {
      return renderText("couponEditor");
    } else if (part === "video-screen-editor") {
      return renderText("videoScreenEditor");
    } else if (part === "new-coupon") {
      return renderText("newCoupon");
    } else {
      return part;
    }
  }

  let parts = [""];
  if (location.pathname !== "/") {
    parts = location.pathname.split("/");
  }

  let componentParts = [];
  for (let i = 0; i < parts.length; i++) {
    let part = parts[i];

    if (i < parts.length - 1) {
      componentParts.push(
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 16,
            color: theme.white,
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <div
            style={{cursor: "pointer", whiteSpace: "nowrap"}}
            onClick={e => {
              let backSteps = parts.length - (i + 1);

              let pathBack = "/";

              for (let p = 1; p <= i; p++) {
                if (p === 1) {
                  pathBack += parts[p];
                } else {
                  pathBack += "/" + parts[p];
                }
              }

              if (
                location.pathname === "/subjects" ||
                location.pathname === "/profile" ||
                location.pathname === "/new-app"
              ) {
                navigate(pathBack);
              } else {
                navigate(pathBack, {
                  idApp: location.state.app._id,
                  app: location.state.app,
                  state: location.state
                });
              }
            }}
          >
            {translatePart(part)}
          </div>

          <Icon
            id={"test"}
            font={"MaterialIcons"}
            name={"chevron-right"}
            size={20}
            color={"#808080"}
            style={{marginTop: 3}}
          />
        </div>
      );
    } else {
      componentParts.push(
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            margin: 0,
            fontSize: 16,
            color: "#4cb85c",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <div style={{cursor: "pointer"}}>{translatePart(part)}</div>
        </div>
      );
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: theme.background.breadcrumbs,
        borderRadius: "10px",
        width: "90%",
        height: "40px"
      }}
    >
      {componentParts}
    </div>
  );
};

export default Breadcrumbs;
