const labels = {
  signin: {
    title: [
      { language: "cs", label: "Přihlaste se pomocí e-mailové adresy" },
      { language: "en", label: "" },
    ],
    email: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    emailError1: [
      { language: "cs", label: "Email je povinný" },
      { language: "en", label: "" },
    ],
    emailError2: [
      { language: "cs", label: "Email musí být platný" },
      { language: "en", label: "" },
    ],
    password: [
      { language: "cs", label: "Heslo" },
      { language: "en", label: "" },
    ],
    passwordError1: [
      { language: "cs", label: "Je vyžadováno heslo" },
      { language: "en", label: "" },
    ],
    signin: [
      { language: "cs", label: "Přihlásit" },
      { language: "en", label: "" },
    ],
    signinAccountNotExists: [
      { language: "cs", label: "Účet neexistuje" },
      { language: "en", label: "" },
    ],
    signinWrongPassword: [
      { language: "cs", label: "Špatné heslo" },
      { language: "en", label: "" },
    ],
    dashboardSubjects: [
      { language: "cs", label: "Subjekty" },
      { language: "en", label: "" },
    ],
    dashboardStudioUsers: [
      { language: "cs", label: "Uživatelé studia" },
      { language: "en", label: "" },
    ],
    dashboardAppUsers: [
      { language: "cs", label: "Uživatelé aplikací" },
      { language: "en", label: "" },
    ],
    dashboardApps: [
      { language: "cs", label: "Aplikace" },
      { language: "en", label: "" },
    ],
    accountSettings: [
      { language: "cs", label: "Nastavení účtu" },
      { language: "en", label: "" },
    ],
    signout: [
      { language: "cs", label: "Odhlásit" },
      { language: "en", label: "" },
    ],
    menuDashboard: [
      { language: "cs", label: "Přehled" },
      { language: "en", label: "" },
    ],
    menuSectionsAplications: [
      { language: "cs", label: "Správa Aplikací" },
      { language: "en", label: "" },
    ],
    menuAplications: [
      { language: "cs", label: "Aplikace" },
      { language: "en", label: "" },
    ],
    menuSectionsAccounts: [
      { language: "cs", label: "Správa Účtů" },
      { language: "en", label: "" },
    ],
    menuSubjects: [
      { language: "cs", label: "Subjekty" },
      { language: "en", label: "" },
    ],
    menuUsers: [
      { language: "cs", label: "Uživatelé" },
      { language: "en", label: "" },
    ],
    menuUsers: [
      { language: "cs", label: "Uživatelé Aplikací" },
      { language: "en", label: "" },
    ],
  },
  apps: {
    find: [
      { language: "cs", label: "Hledat podle názvu aplikace" },
      { language: "en", label: "" },
    ],
  },
  newApp: {
    title: [
      { language: "cs", label: "Vytvoření nové aplikace" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit novou aplikaci" },
      { language: "en", label: "" },
    ],
  },
  appSections: {
    notificationsTitle: [
      { language: "cs", label: "Poslat oznámení" },
      { language: "en", label: "" },
    ],
    tutorialsTitle: [
      { language: "cs", label: "Šablony a ukázky" },
      { language: "en", label: "" },
    ],
    guidesTitle: [
      { language: "cs", label: "Návody" },
      { language: "en", label: "" },
    ],
    managementTitle: [
      { language: "cs", label: "Spravovat moduly" },
      { language: "en", label: "" },
    ],
    editTitle: [
      { language: "cs", label: "Upravit obsah aplikace" },
      { language: "en", label: "" },
    ],
    appNewsTitle: [
      { language: "cs", label: "Novinky ve Vaší aplikaci" },
      { language: "en", label: "New messages in your application" },
    ],
    studioNewsTitle: [
      { language: "cs", label: "Novinky ve Studiu" },
      { language: "en", label: "New feature in Studio" },
    ],
    customerEmailModalTitle: [
      {
        language: "cs",
        label: "Novinky",
      },
      { language: "en", label: "" },
    ],
    oldAppNewsTitle: [
      { language: "cs", label: "Načíst starší" },
      { language: "en", label: "" },
    ],
    contactUsTitle: [
      { language: "cs", label: "Kontaktovat podporu" },
      { language: "en", label: "" },
    ],
    appStateTitle: [
      { language: "cs", label: "Aktuální stav aplikace" },
      { language: "en", label: "" },
    ],
    appStatePropagationTitle: [
      { language: "cs", label: "Aktuální propagace aplikace" },
      { language: "en", label: "" },
    ],
    checkboxTitle: [
      { language: "cs", label: "Aplikace je hotová" },
      { language: "en", label: "" },
    ],
    closeMessagesModal: [
      { language: "cs", label: "Zavřit" },
      { language: "en", label: "" },
    ],
    tipsTitle: [
      { language: "cs", label: "Tipy a návody" },
      { language: "en", label: "" },
    ],
    messageTitlePlaceholder: [
      { language: "cs", label: "Zde napište nadpis zprávy" },
      { language: "en", label: "" },
    ],
    messageTextPlaceholder: [
      { language: "cs", label: "Zde napište text zprávy" },
      { language: "en", label: "" },
    ],
    newMessage: [
      { language: "cs", label: "Zde napište novou zprávu" },
      { language: "en", label: "" },
    ],
    saveMessage: [
      { language: "cs", label: "Uložit zprávu" },
      { language: "en", label: "" },
    ],
    customerEmailText: [
      { language: "cs", label: "Zde napište text emailu" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Odeslat oznámení" },
      { language: "en", label: "" },
    ],
    sendCustomerEmail: [
      { language: "cs", label: "Odeslat zákazníkovi" },
      { language: "en", label: "" },
    ],
    quickAccessTitle: [
      { language: "cs", label: "Rychlé akce" },
      { language: "en", label: "" },
    ],
    addShortcut: [
      { language: "cs", label: "Přidat zkratku" },
      { language: "en", label: "" },
    ],
    shortcutModalTitle: [
      { language: "cs", label: "Zkratky" },
      { language: "en", label: "" },
    ],
    screen: [
      { language: "cs", label: "Stránka" },
      { language: "en", label: "" },
    ],
    screenShortcut: [
      { language: "cs", label: "stránka" },
      { language: "en", label: "" },
    ],
    section: [
      { language: "cs", label: "Sekce" },
      { language: "en", label: "" },
    ],
    sectionShortcut: [
      { language: "cs", label: "sekce" },
      { language: "en", label: "" },
    ],
    mapShortcut: [
      { language: "cs", label: "mapa" },
      { language: "en", label: "" },
    ],
    leafletShortcut: [
      { language: "cs", label: "zpravodaj" },
      { language: "en", label: "" },
    ],
    addStudioUser: [
      { language: "cs", label: "Přidat účet studia" },
      { language: "en", label: "" },
    ],
    manageMap: [
      { language: "cs", label: "Spravovat mapu" },
      { language: "en", label: "" },
    ],
    manageLeaflet: [
      { language: "cs", label: "Spravovat zpravodaj" },
      { language: "en", label: "" },
    ],
    manageQuizzes: [
      { language: "cs", label: "Spravovat kvízy" },
      { language: "en", label: "" },
    ],
    manageForms: [
      { language: "cs", label: "Spravovat formuláře" },
      { language: "en", label: "" },
    ],
    manageSystemMessages: [
      { language: "cs", label: "Spravovat systémové hlášky" },
      { language: "en", label: "" },
    ],
    manageAppImage: [
      { language: "cs", label: "Změnit úvodní obrázek aplikace" },
      { language: "en", label: "" },
    ],
    manageNotifications: [
      { language: "cs", label: "Nastavit notifikační zprávy" },
      { language: "en", label: "" },
    ],
    manageWelcome: [
      { language: "cs", label: "Nastavit úvodní jednorázovou informaci" },
      { language: "en", label: "" },
    ],
    newsTitlePlaceholder: [
      { language: "cs", label: "Zde napište nadpis novinky" },
      { language: "en", label: "" },
    ],
    newsTextPlaceholder: [
      { language: "cs", label: "Zde napište text novinky" },
      { language: "en", label: "" },
    ],
    newNews: [
      { language: "cs", label: "Zde napište novinku" },
      { language: "en", label: "" },
    ],
    saveNews: [
      { language: "cs", label: "Uložit novinku" },
      { language: "en", label: "" },
    ],
    customerEmailHistory: [
      { language: "cs", label: "Historie zákaznických emailů" },
      { language: "en", label: "" },
    ],
  },
  screens: {
    title: [
      { language: "cs", label: "Úprava aplikace" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    filter: [
      { language: "cs", label: "Filtr" },
      { language: "en", label: "" },
    ],
    newScreen: [
      { language: "cs", label: "Vytvořit nový screen" },
      { language: "en", label: "" },
    ],
    index: [
      { language: "cs", label: "Index" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
  },
  screen: {
    title: [
      { language: "cs", label: "Stránka" },
      { language: "en", label: "" },
    ],
    baseParameters: [
      { language: "cs", label: "Základní nastavení stránky" },
      { language: "en", label: "" },
    ],
    drawer: [
      { language: "cs", label: "Postranní menu aplikace" },
      { language: "en", label: "" },
    ],
    tabs: [
      { language: "cs", label: "Dolní navigační lišta" },
      { language: "en", label: "" },
    ],
    backgroundImage: [
      { language: "cs", label: "Obrázek pozadí" },
      { language: "en", label: "" },
    ],
    headerImage: [
      { language: "cs", label: "Obrázek hlavičky" },
      { language: "en", label: "" },
    ],
    editor: [
      { language: "cs", label: "Editor stránky" },
      { language: "en", label: "" },
    ],
    videoScreenEditor: [
      { language: "cs", label: "Editor stránky s videem" },
      { language: "en", label: "" },
    ],
    delete: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],

    theme: [
      { language: "cs", label: "Téma" },
      { language: "en", label: "" },
    ],
  },
  screenBasicParameters: {
    title: [
      { language: "cs", label: "Základní nastavení stránky" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    prefix: [
      { language: "cs", label: "Prefix kódu" },
      { language: "en", label: "" },
    ],
    type: [
      { language: "cs", label: "typ" },
      { language: "en", label: "" },
    ],
    home: [
      { language: "cs", label: "Home" },
      { language: "en", label: "" },
    ],
    webview: [
      { language: "cs", label: "Vložený web" },
      { language: "en", label: "" },
    ],
    useWebviewBackHistory: [
      {
        language: "cs",
        label: "Použít šipku zpět k procházení historie ve vloženém prohlížeči",
      },
      { language: "en", label: "" },
    ],
    container: [
      { language: "cs", label: "Kontejner (dočasně neaktivní)" },
      { language: "en", label: "" },
    ],
    slider: [
      { language: "cs", label: "Posuvná sada stránek (Slider)" },
      { language: "en", label: "" },
    ],
    gallery: [
      { language: "cs", label: "Galerie" },
      { language: "en", label: "" },
    ],
    rssFeed: [
      { language: "cs", label: "RSS Feed" },
      { language: "en", label: "" },
    ],
    video: [
      { language: "cs", label: "Video" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    textSize: [
      { language: "cs", label: "Velikost textu" },
      { language: "en", label: "" },
    ],
    icon: [
      { language: "cs", label: "Ikona" },
      { language: "en", label: "" },
    ],
    useHeader: [
      { language: "cs", label: "Zobrazit horní lištu" },
      { language: "en", label: "" },
    ],
    useAnimatedHeader: [
      { language: "cs", label: "Zobrazit animovaný obrázek" },
      { language: "en", label: "" },
    ],
    useHeaderLightBlur: [
      { language: "cs", label: "Použít rozmazání horní lišty (světlé)" },
      { language: "en", label: "" },
    ],
    useHeaderDarkBlur: [
      { language: "cs", label: "Použít rozmazání horní lišty (tmavé)" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBackgroundFullscreen: [
      { language: "cs", label: "Nastavit pozadí na celou obrazovku" },
      { language: "en", label: "" },
    ],
    useBackground: [
      { language: "cs", label: "Použít obrázek na pozadí" },
      { language: "en", label: "" },
    ],
    useToggler: [
      { language: "cs", label: "Zobrazit menu v horní liště" },
      { language: "en", label: "" },
    ],
    useBack: [
      { language: "cs", label: "Zobrazit šipku zpět v horní liště" },
      { language: "en", label: "" },
    ],
    useRefresh: [
      { language: "cs", label: "Umožnit obnovení stránky tažením dolů" },
      { language: "en", label: "" },
    ],
    backSize: [
      { language: "cs", label: "Velikost šipky" },
      { language: "en", label: "" },
    ],
    useTabs: [
      { language: "cs", label: "Zobrazit dolní navigační lištu" },
      { language: "en", label: "" },
    ],
    useGestures: [
      { language: "cs", label: "Povolit krok zpět gestem" },
      { language: "en", label: "" },
    ],
    useTitle: [
      { language: "cs", label: "Zobrazit nadpis v horní liště" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    lightPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí" },
      { language: "en", label: "" },
    ],
    lightSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    darkPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí" },
      { language: "en", label: "" },
    ],
    darkSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu" },
      { language: "en", label: "" },
    ],
    statusBarStyle: [
      { language: "cs", label: "Téma informační lišty telefonu" },
      { language: "en", label: "" },
    ],
    randomSlides: [
      { language: "cs", label: "Zamíchat s pořadím" },
      { language: "en", label: "" },
    ],
    paidSection: [
      { language: "cs", label: "Placená sekce" },
      { language: "en", label: "" },
    ],
    useWebview4Rss: [
      { language: "cs", label: "Použít webview pro odkazy" },
      { language: "en", label: "" },
    ],
    useLinkRss: [
      { language: "cs", label: "Použít odkaz ihned po kliku na položku" },
      { language: "en", label: "" },
    ],
    rssFeedUrl: [
      { language: "cs", label: "Url" },
      { language: "en", label: "" },
    ],
    videoUrl: [
      { language: "cs", label: "Url" },
      { language: "en", label: "" },
    ],
    filePlaceholder: [
      { language: "cs", label: "Zde nahrajte .mp4 soubor" },
      { language: "en", label: "" },
    ],
    fileUploaded: [
      { language: "cs", label: "Soubor je nahran" },
      { language: "en", label: "" },
    ],
    videoMute: [
      { language: "cs", label: "Ztlumit zvuk videa" },
      { language: "en", label: "" },
    ],
    webviewUrl: [
      { language: "cs", label: "Url" },
      { language: "en", label: "" },
    ],
    rssBackgroundColorLight: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    rssItemBackgroundColorLight: [
      { language: "cs", label: "Barva pozadí položky" },
      { language: "en", label: "" },
    ],
    rssItemTextColorLight: [
      { language: "cs", label: "Barva textu položky" },
      { language: "en", label: "" },
    ],
    rssItemDateColorLight: [
      { language: "cs", label: "Barva datumu položky" },
      { language: "en", label: "" },
    ],
    rssDetailBackgroundColorLight: [
      { language: "cs", label: "Barva pozadí detailu" },
      { language: "en", label: "" },
    ],
    rssDetailTitleColorLight: [
      { language: "cs", label: "Barva nadpisu detailu" },
      { language: "en", label: "" },
    ],
    rssDetailDescriptionColorLight: [
      { language: "cs", label: "Barva popisu detailu" },
      { language: "en", label: "" },
    ],
    rssDetailButtonBackgroundColorLight: [
      { language: "cs", label: "Barva pozadí tlačítka detailu" },
      { language: "en", label: "" },
    ],
    rssDetailButtonTextColorLight: [
      { language: "cs", label: "Barva textu tlačítka detailu" },
      { language: "en", label: "" },
    ],
    rssBackgroundColorDark: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    videoBackgroundColorLight: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    videoBackgroundColorDark: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    videoTextColorLight: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    videoTextColorDark: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    rssItemBackgroundColorDark: [
      { language: "cs", label: "Barva pozadí položky" },
      { language: "en", label: "" },
    ],
    rssItemTextColorDark: [
      { language: "cs", label: "Barva textu položky" },
      { language: "en", label: "" },
    ],
    rssItemDateColorDark: [
      { language: "cs", label: "Barva datumu položky" },
      { language: "en", label: "" },
    ],
    rssDetailBackgroundColorDark: [
      { language: "cs", label: "Barva pozadí položky" },
      { language: "en", label: "" },
    ],
    rssDetailTitleColorDark: [
      { language: "cs", label: "Barva nadpisu detailu" },
      { language: "en", label: "" },
    ],
    rssDetailDescriptionColorDark: [
      { language: "cs", label: "Barva popisu detailu" },
      { language: "en", label: "" },
    ],
    rssDetailButtonBackgroundColorDark: [
      { language: "cs", label: "Barva pozadí tlačítka detailu" },
      { language: "en", label: "" },
    ],
    rssDetailButtonTextColorDark: [
      { language: "cs", label: "Barva textu tlačítka detailu" },
      { language: "en", label: "" },
    ],
    injectedJavaScriptEnabled: [
      { language: "cs", label: "Vložit JavaScript" },
      { language: "en", label: "" },
    ],

    save: [
      { language: "cs", label: "Potvrdit změny nastavení" },
      { language: "en", label: "" },
    ],
  },
  screenDrawer: {
    title: [
      { language: "cs", label: "Postranní menu aplikace" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBlurLight: [
      { language: "cs", label: "Použít rozmazání pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    useBlurDark: [
      { language: "cs", label: "Použít rozmazání pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    lightItemColor: [
      { language: "cs", label: "Barva ikony a textu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    darkItemColor: [
      { language: "cs", label: "Barva ikony a textu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
  },
  screenTabs: {
    title: [
      { language: "cs", label: "Dolní navigační lišta" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBlurLight: [
      { language: "cs", label: "Rozmazat pozadí lišty (světlé téma)" },
      { language: "en", label: "" },
    ],
    useBlurDark: [
      { language: "cs", label: "Rozmazat pozadí lišty (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightActiveBackgroundColor: [
      { language: "cs", label: "Barva pozadí aktivní ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    lightInactiveBackgroundColor: [
      { language: "cs", label: "Barva pozadí neaktivní ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    lightActiveColor: [
      { language: "cs", label: "Barva aktivní ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    lightInactiveColor: [
      { language: "cs", label: "Barva neaktivní ikony (světlé téma)" },
    ],
    darkActiveBackgroundColor: [
      { language: "cs", label: "Barva pozadí aktivní ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    darkInactiveBackgroundColor: [
      { language: "cs", label: "Barva pozadí neaktivní ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    darkActiveColor: [
      { language: "cs", label: "Barva aktivní ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    darkInactiveColor: [
      { language: "cs", label: "Barva neaktivní ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
  },
  screenBackgroundImage: {
    title: [
      { language: "cs", label: "Obrázek pozadí" },
      { language: "en", label: "" },
    ],
  },
  screenHeaderImage: {
    title: [
      { language: "cs", label: "Obrázek hlavičky" },
      { language: "en", label: "" },
    ],
  },
  screenEditor: {
    title: [
      { language: "cs", label: "Editor stránky" },
      { language: "en", label: "" },
    ],
    tools: [
      { language: "cs", label: "Přidání nových prvků" },
      { language: "en", label: "" },
    ],
    groups: [
      { language: "cs", label: "Skupiny komponent" },
      { language: "en", label: "" },
    ],
    menuItems: [
      { language: "cs", label: "Tlačítka" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Textové pole" },
      { language: "en", label: "" },
    ],
    cards: [
      { language: "cs", label: "Karty" },
      { language: "en", label: "" },
    ],
    images: [
      { language: "cs", label: "Obrázky" },
      { language: "en", label: "" },
    ],
    formItems: [
      { language: "cs", label: "Formulářové prvky" },
      { language: "en", label: "" },
    ],
    next: [
      { language: "cs", label: "Další" },
      { language: "en", label: "" },
    ],
    group1Column: [
      { language: "cs", label: "1 sloupec" },
      { language: "en", label: "" },
    ],
    group2Column: [
      { language: "cs", label: "2 sloupce" },
      { language: "en", label: "" },
    ],
    group3Column: [
      { language: "cs", label: "3 sloupce" },
      { language: "en", label: "" },
    ],
    menuItemIconOnly: [
      { language: "cs", label: "Ikona bez textu" },
      { language: "en", label: "" },
    ],
    menuItemIcon: [
      { language: "cs", label: "Ikona a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemImage: [
      { language: "cs", label: "Obrázek a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemText: [
      { language: "cs", label: "Samostatný text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemIcon2: [
      { language: "cs", label: "Ikona a text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemImage2: [
      { language: "cs", label: "Obrázek a text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemText2: [
      { language: "cs", label: "Samostatný text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemIcon3: [
      { language: "cs", label: "Ikona a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemIcon32: [
      { language: "cs", label: "Další text" },
      { language: "en", label: "" },
    ],
    menuItemImage3: [
      { language: "cs", label: "Obrázek a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemImage32: [
      { language: "cs", label: "Další text" },
      { language: "en", label: "" },
    ],
    menuItemText3: [
      { language: "cs", label: "Samostatný text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemText32: [
      { language: "cs", label: "Další text" },
      { language: "en", label: "" },
    ],
    richText: [
      { language: "cs", label: "Editor textového pole" },
      { language: "en", label: "" },
    ],
    card: [
      { language: "cs", label: "Karta s obrázkem" },
      { language: "en", label: "" },
    ],
    videoCard: [
      { language: "cs", label: "Karta s videem" },
      { language: "en", label: "" },
    ],
    videoCard2: [
      { language: "cs", label: "Karta s videem ve smyčce" },
      { language: "en", label: "" },
    ],
    audioCard: [
      { language: "cs", label: "Karta s audiem" },
      { language: "en", label: "" },
    ],
    simpleImage: [
      { language: "cs", label: "Obrázek" },
      { language: "en", label: "" },
    ],
    textImage: [
      { language: "cs", label: "Obrázek a text" },
      { language: "en", label: "" },
    ],
    formTextInput: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    formSwitch: [
      { language: "cs", label: "Checkbox" },
      { language: "en", label: "" },
    ],
    formSlider: [
      { language: "cs", label: "Posuvník" },
      { language: "en", label: "" },
    ],
    formTime: [
      { language: "cs", label: "Výběr času" },
      { language: "en", label: "" },
    ],
    formSelect: [
      { language: "cs", label: "Výběr z možností" },
      { language: "en", label: "" },
    ],
    formLocation: [
      { language: "cs", label: "Lokalizace" },
      { language: "en", label: "" },
    ],
    formUploadImage: [
      { language: "cs", label: "Nahrání obrázku" },
      { language: "en", label: "" },
    ],
    carousel: [
      { language: "cs", label: "Posuvník obrázků" },
      { language: "en", label: "" },
    ],
    webview: [
      { language: "cs", label: "Zobrazení webu" },
      { language: "en", label: "" },
    ],
    menuDivider: [
      { language: "cs", label: "Oddělovač" },
      { language: "en", label: "" },
    ],
    screenCheckButton: [
      { language: "cs", label: "Tlačítko pro označení stránky" },
      { language: "en", label: "" },
    ],
    noVideoPlaceholder: [
      { language: "cs", label: "Video není hahrano" },
      { language: "en", label: "" },
    ],
  },
  screenEditorGroup: {
    title: [
      { language: "cs", label: "Skupina komponent" },
      { language: "en", label: "" },
    ],
    formName: [
      { language: "cs", label: "Název formuláře" },
      { language: "en", label: "" },
    ],
    type: [
      { language: "cs", label: "typ" },
      { language: "en", label: "" },
    ],
    accordion: [
      { language: "cs", label: "Rozbalovací" },
      { language: "en", label: "" },
    ],
    unrolled: [
      { language: "cs", label: "Rozbaleno" },
      { language: "en", label: "" },
    ],
    none: [
      { language: "cs", label: "Žádný" },
      { language: "en", label: "" },
    ],
    icon: [
      { language: "cs", label: "ikona" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "obrázek" },
      { language: "en", label: "" },
    ],
    group: [
      { language: "cs", label: "skupina" },
      { language: "en", label: "" },
    ],
    form: [
      { language: "cs", label: "formulář" },
      { language: "en", label: "" },
    ],
    normal: [
      { language: "cs", label: "normální" },
      { language: "en", label: "" },
    ],
    pdf: [
      { language: "cs", label: "pdf" },
      { language: "en", label: "" },
    ],
    width: [
      { language: "cs", label: "Šířka v procentech" },
      { language: "en", label: "" },
    ],
    paddingTop: [
      { language: "cs", label: "Odsazení nahoru" },
      { language: "en", label: "" },
    ],
    paddingBottom: [
      { language: "cs", label: "Odsazení dolů" },
      { language: "en", label: "" },
    ],
    internalPadding: [
      { language: "cs", label: "Vnitřní odsazení" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    textSize: [
      { language: "cs", label: "Velikost textu" },
      { language: "en", label: "" },
    ],
    iconSize: [
      { language: "cs", label: "Velikost ikony" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBlurLight: [
      { language: "cs", label: "Použít rozmazání (světlé téma)" },
      { language: "en", label: "" },
    ],
    useBlurDark: [
      { language: "cs", label: "Použít rozmazání (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightTextColor: [
      { language: "cs", label: "Barva textu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkTextColor: [
      { language: "cs", label: "Barva textu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightIconColor: [
      { language: "cs", label: "Barva ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkIconColor: [
      { language: "cs", label: "Barva ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightButtonIconColor: [
      { language: "cs", label: "Barva ikony tlačítka (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkButtonIconColor: [
      { language: "cs", label: "Barva ikony tlačítka(tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightButtonColor: [
      { language: "cs", label: "Barva tlačítka (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkButtonColor: [
      { language: "cs", label: "Barva tlačítka (tmavé téma)" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    duplicate: [
      { language: "cs", label: "Duplikovat" },
      { language: "en", label: "" },
    ],
    formSettings: [
      { language: "cs", label: "Nastavení formuláře" },
      { language: "en", label: "" },
    ],
  },
  screenEditorComponent: {
    title: [
      { language: "cs", label: "Úprava prvků" },
      { language: "en", label: "" },
    ],
    type: [
      { language: "cs", label: "Typ komponenty" },
      { language: "en", label: "" },
    ],
    group1Column: [
      { language: "cs", label: "1 sloupec" },
      { language: "en", label: "" },
    ],
    group2Column: [
      { language: "cs", label: "2 sloupce" },
      { language: "en", label: "" },
    ],
    group3Column: [
      { language: "cs", label: "3 sloupce" },
      { language: "en", label: "" },
    ],
    menuItemIconOnly: [
      { language: "cs", label: "Ikona bez textu" },
      { language: "en", label: "" },
    ],
    menuItemIcon: [
      { language: "cs", label: "Ikona a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemImage: [
      { language: "cs", label: "Obrázek a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemText: [
      { language: "cs", label: "Samostatný text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemIcon2: [
      { language: "cs", label: "Ikona a text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemImage2: [
      { language: "cs", label: "Obrázek a text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemText2: [
      { language: "cs", label: "Samostatný text vertikálně" },
      { language: "en", label: "" },
    ],
    menuItemIcon3: [
      { language: "cs", label: "Ikona a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemImage3: [
      { language: "cs", label: "Obrázek a text horizontálně" },
      { language: "en", label: "" },
    ],
    menuItemText3: [
      { language: "cs", label: "Samostatný text horizontálně" },
      { language: "en", label: "" },
    ],
    richText: [
      { language: "cs", label: "Editor textového pole" },
      { language: "en", label: "" },
    ],
    simpleImage: [
      { language: "cs", label: "Obrázek" },
      { language: "en", label: "" },
    ],
    textImage: [
      { language: "cs", label: "Obrázek s textem" },
      { language: "en", label: "" },
    ],
    card: [
      { language: "cs", label: "Karta s obrázkem" },
      { language: "en", label: "" },
    ],
    videoCard: [
      { language: "cs", label: "Karta s videem" },
      { language: "en", label: "" },
    ],
    videoCard2: [
      { language: "cs", label: "Karta s videem ve smyčce" },
      { language: "en", label: "" },
    ],
    audioCard: [
      { language: "cs", label: "Karta s audiem" },
      { language: "en", label: "" },
    ],
    formTextInput: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    formSwitch: [
      { language: "cs", label: "Checkbox" },
      { language: "en", label: "" },
    ],
    formSlider: [
      { language: "cs", label: "Posuvník" },
      { language: "en", label: "" },
    ],
    formTime: [
      { language: "cs", label: "Výběr termínu" },
      { language: "en", label: "" },
    ],
    formSelect: [
      { language: "cs", label: "Výběr z možností" },
      { language: "en", label: "" },
    ],
    formLocation: [
      { language: "cs", label: "Lokalizace" },
      { language: "en", label: "" },
    ],
    formUploadImage: [
      { language: "cs", label: "Nahrání obrázku" },
      { language: "en", label: "" },
    ],
    carousel: [
      { language: "cs", label: "Posuvník obrázků" },
      { language: "en", label: "" },
    ],
    webview: [
      { language: "cs", label: "Zobrazení webu" },
      { language: "en", label: "" },
    ],
    menuDivider: [
      { language: "cs", label: "Oddělovač" },
      { language: "en", label: "" },
    ],
    screenCheckButton: [
      { language: "cs", label: "Tlačítko pro označení stránky" },
      { language: "en", label: "" },
    ],
    paddingTop: [
      { language: "cs", label: "Odsazení nahoru" },
      { language: "en", label: "" },
    ],
    paddingBottom: [
      { language: "cs", label: "Odsazení dolů" },
      { language: "en", label: "" },
    ],
    min: [
      { language: "cs", label: "Minimální hodnota" },
      { language: "en", label: "" },
    ],
    max: [
      { language: "cs", label: "Maximální hodnota" },
      { language: "en", label: "" },
    ],
    step: [
      { language: "cs", label: "Krok" },
      { language: "en", label: "" },
    ],
    default: [
      { language: "cs", label: "Hodnota" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    textSize: [
      { language: "cs", label: "Velikost textu" },
      { language: "en", label: "" },
    ],
    text2: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    textSize2: [
      { language: "cs", label: "Velikost textu" },
      { language: "en", label: "" },
    ],
    borderWidth: [
      { language: "cs", label: "Tloušťka ohraničení" },
      { language: "en", label: "" },
    ],
    borderRadius: [
      { language: "cs", label: "Zaoblení rohů" },
      { language: "en", label: "" },
    ],
    left: [
      { language: "cs", label: "Vlevo" },
      { language: "en", label: "" },
    ],
    right: [
      { language: "cs", label: "Vpravo" },
      { language: "en", label: "" },
    ],
    center: [
      { language: "cs", label: "Na střed" },
      { language: "en", label: "" },
    ],
    video: [
      { language: "cs", label: "Url videa" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBlurLight: [
      { language: "cs", label: "Použít rozmazání (světlé téma)" },
      { language: "en", label: "" },
    ],
    useBlurDark: [
      { language: "cs", label: "Použít rozmazání (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightTextColor: [
      { language: "cs", label: "Barva textu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkTextColor: [
      { language: "cs", label: "Barva textu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightIconColor: [
      { language: "cs", label: "Barva ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkIconColor: [
      { language: "cs", label: "Barva ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightBorderColor: [
      { language: "cs", label: "Barva ohraničení (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkBorderColor: [
      { language: "cs", label: "Barva ohraničení (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightOverlayColor: [
      { language: "cs", label: "Barva překrytí (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkOverlayColor: [
      { language: "cs", label: "Barva překrytí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    noAction: [
      { language: "cs", label: "Bez akce" },
      { language: "en", label: "" },
    ],
    navigationAction: [
      { language: "cs", label: "Přesunout na jinou stránku" },
      { language: "en", label: "" },
    ],
    urlAction: [
      { language: "cs", label: "Otevřít webovou stránku mimo aplikaci" },
      { language: "en", label: "" },
    ],
    phoneAction: [
      { language: "cs", label: "Vytočit telefonní číslo" },
      { language: "en", label: "" },
    ],
    emailAction: [
      { language: "cs", label: "Otevřít e-mail" },
      { language: "en", label: "" },
    ],
    smsAction: [
      { language: "cs", label: "Napsat SMS zprávu" },
      { language: "en", label: "" },
    ],
    webviewAction: [
      { language: "cs", label: "Otevřít webovou stránku uvnitř aplikace" },
      { language: "en", label: "" },
    ],
    chatAction: [
      { language: "cs", label: "Otevřít chat" },
      { language: "en", label: "" },
    ],
    mapGpsAction: [
      { language: "cs", label: "Otevřít mapu (zadat GPS souřadnice)" },
      { language: "en", label: "" },
    ],
    mapAddressAction: [
      { language: "cs", label: "Otevřít mapu (zadat adresu)" },
      { language: "en", label: "" },
    ],
    quizAction: [
      { language: "cs", label: "Spustit kvíz" },
      { language: "en", label: "" },
    ],
    mindfulnessAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessProAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessTouchAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mapAction: [
      { language: "cs", label: "Otevřít interní mapu" },
      { language: "en", label: "" },
    ],
    mapAction2: [
      { language: "cs", label: "Otevřít interaktivní mapu" },
      { language: "en", label: "" },
    ],
    pdfAction: [
      { language: "cs", label: "Zobrazit dokument PDF" },
      { language: "en", label: "" },
    ],
    videoAction: [
      { language: "cs", label: "Přehrát video" },
      { language: "en", label: "" },
    ],
    audioAction: [
      { language: "cs", label: "Přehrát zvuk" },
      { language: "en", label: "" },
    ],
    settingsAction: [
      { language: "cs", label: "Otevřít nastavení aplikace v telefonu" },
      { language: "en", label: "" },
    ],
    leafletAction: [
      { language: "cs", label: "Otevřít leták" },
      { language: "en", label: "" },
    ],
    couponAction: [
      { language: "cs", label: "Otevřít kupón" },
      { language: "en", label: "" },
    ],
    multiCouponAction: [
      { language: "cs", label: "Otevřít multikupón" },
      { language: "en", label: "" },
    ],
    nationHealthAction: [
      { language: "cs", label: "Národní zdraví" },
      { language: "en", label: "" },
    ],
    birthdayAction: [
      { language: "cs", label: "Narozeniny" },
      { language: "en", label: "" },
    ],
    couponsAction: [
      { language: "cs", label: "Otevřít kupóny" },
      { language: "en", label: "" },
    ],
    paroAction: [
      { language: "cs", label: "Participační rozpočet" },
      { language: "en", label: "" },
    ],
    paroResultsAction: [
      { language: "cs", label: "Výsledky participačního rozpočtu" },
      { language: "en", label: "" },
    ],
    accomodationAction: [
      { language: "cs", label: "Sekce pro ubytovatele" },
      { language: "en", label: "" },
    ],
    accomodationAccountAction: [
      { language: "cs", label: "Sekce pro ubytovatele - účet" },
      { language: "en", label: "" },
    ],
    accomodationPropertyAction: [
      { language: "cs", label: "Sekce pro ubytovatele - ubytovací jednotky" },
      { language: "en", label: "" },
    ],
    shoptetAction: [
      { language: "cs", label: "Shoptet" },
      { language: "en", label: "" },
    ],
    icon: [
      { language: "cs", label: "Ikona" },
      { language: "en", label: "" },
    ],
    iconSize: [
      { language: "cs", label: "Velikost" },
      { language: "en", label: "" },
    ],
    imageRadius: [
      { language: "cs", label: "Zaoblení obrázku" },
      { language: "en", label: "" },
    ],
    imageDropText: [
      {
        language: "cs",
        label: "Zde klikněte pro výběr obrázku nebo sem obrázek přetáhněte.",
      },
      { language: "en", label: "" },
    ],
    editText: [
      { language: "cs", label: "Editace" },
      { language: "en", label: "" },
    ],
    richTextTitle: [
      { language: "cs", label: "Editor textového pole" },
      { language: "en", label: "" },
    ],
    richTextSave: [
      { language: "cs", label: "Uložit změny" },
      { language: "en", label: "" },
    ],
    carouselAddItem: [
      { language: "cs", label: "Přidat" },
      { language: "en", label: "" },
    ],
    saveCarousel: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    required: [
      { language: "cs", label: "Povinné pole" },
      { language: "en", label: "" },
    ],
    textInputType: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    numberInputType: [
      { language: "cs", label: "Číslo" },
      { language: "en", label: "" },
    ],
    emailInputType: [
      { language: "cs", label: "E-mail" },
      { language: "en", label: "" },
    ],
    passwordInputType: [
      { language: "cs", label: "Heslo" },
      { language: "en", label: "" },
    ],
    time: [
      { language: "cs", label: "Čas" },
      { language: "en", label: "" },
    ],
    date: [
      { language: "cs", label: "Datum" },
      { language: "en", label: "" },
    ],
    datetime: [
      { language: "cs", label: "Datum a čas" },
      { language: "en", label: "" },
    ],
    single: [
      { language: "cs", label: "Jedna možnost" },
      { language: "en", label: "" },
    ],
    multiple: [
      { language: "cs", label: "Více možností" },
      { language: "en", label: "" },
    ],
    addOption: [
      { language: "cs", label: "Přidat možnost" },
      { language: "en", label: "" },
    ],
    optionName: [
      { language: "cs", label: "Název možnosti" },
      { language: "en", label: "" },
    ],
    duplicate: [
      { language: "cs", label: "Duplikovat" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    componentContent: [
      { language: "cs", label: "Obsah komponenty" },
      { language: "en", label: "" },
    ],
    componentBackground: [
      { language: "cs", label: "Pozadí komponenty" },
      { language: "en", label: "" },
    ],
    groupContent: [
      { language: "cs", label: "Obsah skupiny" },
      { language: "en", label: "" },
    ],
    groupBackground: [
      { language: "cs", label: "Pozadí skupiny" },
      { language: "en", label: "" },
    ],
    componentBlur: [
      { language: "cs", label: "Rozmazání komponenty" },
      { language: "en", label: "" },
    ],
    componentDark: [
      { language: "cs", label: "Tmavý mód komponenty" },
      { language: "en", label: "" },
    ],
    componentLanguage: [
      { language: "cs", label: "Jazyky" },
      { language: "en", label: "" },
    ],
    componentOther: [
      { language: "cs", label: "Další" },
      { language: "en", label: "" },
    ],
    groupDark: [
      { language: "cs", label: "Tmavý mód skupiny" },
      { language: "en", label: "" },
    ],
    groupLanguage: [
      { language: "cs", label: "Jazyky" },
      { language: "en", label: "" },
    ],
    groupOther: [
      { language: "cs", label: "Další" },
      { language: "en", label: "" },
    ],
    host: [
      { language: "cs", label: "Host" },
      { language: "en", label: "" },
    ],
    user: [
      { language: "cs", label: "Uživatel" },
      { language: "en", label: "" },
    ],
    admin: [
      { language: "cs", label: "Administrátor" },
      { language: "en", label: "" },
    ],
    hostDescription: [
      {
        language: "cs",
        label: "Viditelnost je pro všechny role bez nutnosti přihlášení.",
      },
      { language: "en", label: "" },
    ],
    userDescription: [
      {
        language: "cs",
        label: "Viditelnost je pro všechny role po přihlášení.",
      },
      { language: "en", label: "" },
    ],
    adminDescription: [
      {
        language: "cs",
        label: "Viditelnost je pouze pro roli admin po přihlášení.",
      },
      { language: "en", label: "" },
    ],
    useWebviewBackHistory: [
      {
        language: "cs",
        label: "Použít šipku zpět k procházení historie ve vloženém prohlížeči",
      },
      { language: "en", label: "" },
    ],
    normal: [
      { language: "cs", label: "Běžné" },
      { language: "en", label: "" },
    ],
    bold: [
      { language: "cs", label: "Tučné" },
      { language: "en", label: "" },
    ],
    textSettings: [
      { language: "cs", label: "Nastavení textu" },
      { language: "en", label: "" },
    ],
    subtextSettings: [
      { language: "cs", label: "Nastavení druhého textu" },
      { language: "en", label: "" },
    ],

    darkTextColor2: [
      { language: "cs", label: "Barva druhého textu" },
      { language: "en", label: "" },
    ],
    iconSettings: [
      { language: "cs", label: "Nastavení ikony" },
      { language: "en", label: "" },
    ],
    imageSettings: [
      { language: "cs", label: "Nastavení obrázku" },
      { language: "en", label: "" },
    ],
    iconImageSettings: [
      { language: "cs", label: "Nastavení ikony/obrázku" },
      { language: "en", label: "" },
    ],
    formButtonSettings: [
      { language: "cs", label: "Nastavení tlačítka formuláře" },
      { language: "en", label: "" },
    ],
    actionSettings: [
      { language: "cs", label: "Nastavení akce" },
      { language: "en", label: "" },
    ],
    formSettings: [
      { language: "cs", label: "Nastavení formulářového prvku" },
      { language: "en", label: "" },
    ],
    backgroundSettings: [
      { language: "cs", label: "Nastavení pozadí" },
      { language: "en", label: "" },
    ],
    colorsSettings: [
      { language: "cs", label: "Nastavení barev" },
      { language: "en", label: "" },
    ],
    languagesTextSettings: [
      { language: "cs", label: "Nastavení textu" },
      { language: "en", label: "" },
    ],
    languagesText2Settings: [
      { language: "cs", label: "Nastavení druhého textu" },
      { language: "en", label: "" },
    ],
    languagesActionSettings: [
      { language: "cs", label: "Nastavení akce" },
      { language: "en", label: "" },
    ],
    carouselSettings: [
      { language: "cs", label: "Nastavení posuvníku obrázků" },
      { language: "en", label: "" },
    ],
    iconTooltip: [
      { language: "cs", label: "Kliknutím vyberte ikonu" },
      { language: "en", label: "" },
    ],
    formTextInputText: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    formTextInputNumber: [
      { language: "cs", label: "Číslo" },
      { language: "en", label: "" },
    ],
    formTextInputEmail: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    formTextInputPassword: [
      { language: "cs", label: "Heslo" },
      { language: "en", label: "" },
    ],
    formRequired: [
      { language: "cs", label: "Povinné" },
      { language: "en", label: "" },
    ],
    formTimeTime: [
      { language: "cs", label: "Čas" },
      { language: "en", label: "" },
    ],
    formTimeDate: [
      { language: "cs", label: "Datum" },
      { language: "en", label: "" },
    ],
    formTimeDateTime: [
      { language: "cs", label: "Datum a čas" },
      { language: "en", label: "" },
    ],
    formSelectSingle: [
      { language: "cs", label: "Pouze jedna možnost" },
      { language: "en", label: "" },
    ],
    formSelectMultiple: [
      { language: "cs", label: "Více možností" },
      { language: "en", label: "" },
    ],
    formMaxImages: [
      { language: "cs", label: "Maximální počet obrázků" },
      { language: "en", label: "" },
    ],
    formCheckMinWidth: [
      { language: "cs", label: "Zkontrolovat minimální šířku" },
      { language: "en", label: "" },
    ],
    formMinWidth: [
      { language: "cs", label: "Minimální šířka" },
      { language: "en", label: "" },
    ],
    formCheckMinHeight: [
      { language: "cs", label: "Zkontrolovat minimální výšku" },
      { language: "en", label: "" },
    ],
    formMinHeight: [
      { language: "cs", label: "Minimální výška" },
      { language: "en", label: "" },
    ],
    formCheckAspectRatio: [
      { language: "cs", label: "Zkontrolovat poměr stran" },
      { language: "en", label: "" },
    ],
    formAspectRatio: [
      { language: "cs", label: "Poměr stran" },
      { language: "en", label: "" },
    ],
    componentHeight: [
      { language: "cs", label: "Výška komponenty" },
      { language: "en", label: "" },
    ],
    imageUpload: [
      { language: "cs", label: "Sem klikněte, nebo přetáhněte obrázek." },
      { language: "en", label: "" },
    ],
    protectionInDetail: [
      { language: "cs", label: "Zobrazit podrobné nastavení" },
      { language: "en", label: "" },
    ],
    injectedJavaScriptEnabled: [
      { language: "cs", label: "Vložit JavaScript" },
      { language: "en", label: "" },
    ],
    shadowEnabled: [
      { language: "cs", label: "Použít stín" },
      { language: "en", label: "" },
    ],
    lightShadowColor: [
      { language: "cs", label: "Barva stínu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkShadowColor: [
      { language: "cs", label: "Barva stínu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    gradientEnabled: [
      { language: "cs", label: "Použít barevný přechod" },
      { language: "en", label: "" },
    ],
    gradientOrientation: [
      { language: "cs", label: "Orientace přechodu" },
      { language: "en", label: "" },
    ],
    gradientOrientationHorizontal: [
      { language: "cs", label: "Horizontálně" },
      { language: "en", label: "" },
    ],
    gradientOrientationVertical: [
      { language: "cs", label: "Vertikálně" },
      { language: "en", label: "" },
    ],
    gradientColor1: [
      { language: "cs", label: "První barva přechodu" },
      { language: "en", label: "" },
    ],
    gradientColor2: [
      { language: "cs", label: "Druhá barva přechodu" },
      { language: "en", label: "" },
    ],
  },
  notifications: {
    title: [
      { language: "cs", label: "Oznámení" },
      { language: "en", label: "" },
    ],
    titleLinks: [
      { language: "cs", label: "Stránky pro registraci odběratelů" },
      { language: "en", label: "" },
    ],
    sendRegistered: [
      {
        language: "cs",
        label: "Notifikační zprávy pro registrované uživatele",
      },
      { language: "en", label: "" },
    ],
    sendPush: [
      { language: "cs", label: "Notifikační zprávy" },
      { language: "en", label: "" },
    ],
    sendGeolocationPush: [
      { language: "cs", label: "Geolokační notifikační zprávy" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "E-maily" },
      { language: "en", label: "" },
    ],
    sendSms: [
      { language: "cs", label: "SMS zprávy" },
      { language: "en", label: "" },
    ],
    history: [
      { language: "cs", label: "Historie notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    schedule: [
      { language: "cs", label: "Naplánovat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "Naplánované notifikační zprávy" },
      { language: "en", label: "" },
    ],
    subscription: [
      { language: "cs", label: "Kopírovat link k odběru upozornění" },
      { language: "en", label: "" },
    ],
    subscriptionEmail: [
      { language: "cs", label: "Kopírovat link k odběru e-mail upozornění" },
      { language: "en", label: "" },
    ],
    subscriptionSms: [
      { language: "cs", label: "Kopírovat link k odběru sms upozornění" },
      { language: "en", label: "" },
    ],
  },
  notificationsPush: {
    title: [
      { language: "cs", label: "Notifikační zprávy" },
      { language: "en", label: "" },
    ],
    sendPush: [
      { language: "cs", label: "Poslat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Poslat e-mail" },
      { language: "en", label: "" },
    ],
    sendSms: [
      { language: "cs", label: "Poslat SMS" },
      { language: "en", label: "" },
    ],
    history: [
      { language: "cs", label: "Historie notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    schedule: [
      { language: "cs", label: "Naplánovat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "Naplánované notifikační zprávy" },
      { language: "en", label: "" },
    ],
    streets: [
      { language: "cs", label: "Nastavení ulic" },
      { language: "en", label: "" },
    ],
    users: [
      { language: "cs", label: "Nastavení uživatelů" },
      { language: "en", label: "" },
    ],
    subscription: [
      { language: "cs", label: "Kopírovat link k odběru upozornění" },
      { language: "en", label: "" },
    ],
  },
  notificationsGeolocationPush: {
    title: [
      { language: "cs", label: "Geolokační notifikační zprávy" },
      { language: "en", label: "" },
    ],
    send: [
      { language: "cs", label: "Poslat geolokační notifikační zprávu" },
      { language: "en", label: "" },
    ],
    history: [
      { language: "cs", label: "Historie geolokačních notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    zones: [
      { language: "cs", label: "Nastavení geolokačních zón" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "Naplánované geolokační notifikační zprávy" },
      { language: "en", label: "" },
    ],
  },
  notificationsEmail: {
    title: [
      { language: "cs", label: "E-maily" },
      { language: "en", label: "" },
    ],
    sendPush: [
      { language: "cs", label: "Poslat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Poslat e-mail" },
      { language: "en", label: "" },
    ],
    sendSmartEmail: [
      { language: "cs", label: "Poslat e-mail (nový formulář)" },
      { language: "en", label: "" },
    ],
    sendSms: [
      { language: "cs", label: "Poslat SMS" },
      { language: "en", label: "" },
    ],
    history: [
      { language: "cs", label: "Historie e-mailů" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    schedule: [
      { language: "cs", label: "Naplánovat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "Naplánované e-maily" },
      { language: "en", label: "" },
    ],

    subscription: [
      { language: "cs", label: "Kopírovat link k odběru upozornění" },
      { language: "en", label: "" },
    ],
  },
  notificationsSms: {
    title: [
      { language: "cs", label: "SMS" },
      { language: "en", label: "" },
    ],
    sendPush: [
      { language: "cs", label: "Poslat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Poslat e-mail" },
      { language: "en", label: "" },
    ],
    sendSms: [
      { language: "cs", label: "Poslat SMS" },
      { language: "en", label: "" },
    ],
    sendSmartSms: [
      { language: "cs", label: "Poslat SMS (nový formulář)" },
      { language: "en", label: "" },
    ],
    history: [
      { language: "cs", label: "Historie SMS zpráv" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení notifikačních zpráv" },
      { language: "en", label: "" },
    ],
    schedule: [
      { language: "cs", label: "Naplánovat notifikační zprávu" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "Naplánované SMS zprávy" },
      { language: "en", label: "" },
    ],
    subscription: [
      { language: "cs", label: "Kopírovat link k odběru upozornění" },
      { language: "en", label: "" },
    ],
  },
  chat: {
    title: [
      { language: "cs", label: "Chat" },
      { language: "en", label: "" },
    ],
    send: [
      { language: "cs", label: "Konverzace s uživateli" },
      { language: "en", label: "" },
    ],

    settings: [
      { language: "cs", label: "Nastavení chatu" },
      { language: "en", label: "" },
    ],
  },
  chatRooms: {
    title: [
      { language: "cs", label: "Chatovací místnosti" },
      { language: "en", label: "" },
    ],
    filter: [
      { language: "cs", label: "Hledej" },
      { language: "en", label: "" },
    ],
  },
  chatRoom: {
    title: [
      { language: "cs", label: "Chat s" },
      { language: "en", label: "" },
    ],
    placeholder: [
      { language: "cs", label: "Zde napište zprávu" },
      { language: "en", label: "" },
    ],
    send: [
      { language: "cs", label: "Odeslat" },
      { language: "en", label: "" },
    ],
  },
  chatSettings: {
    title: [
      { language: "cs", label: "Nastavení chatu" },
      { language: "en", label: "" },
    ],
    backgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    textColor: [
      { language: "cs", label: "Barva šipky zpět" },
      { language: "en", label: "" },
    ],
    chatBackgroundColor: [
      { language: "cs", label: "Barva pozadí chatu" },
      { language: "en", label: "" },
    ],
    chatTextColor: [
      { language: "cs", label: "Barva textu chatu" },
      { language: "en", label: "" },
    ],
    rightBubbleBackgroundColor: [
      { language: "cs", label: "Barva pozadí bubliny vpravo" },
      { language: "en", label: "" },
    ],
    leftBubbleBackgroundColor: [
      { language: "cs", label: "Barva pozadí bubliny vlevo" },
      { language: "en", label: "" },
    ],
    rightBubbleTextColor: [
      { language: "cs", label: "Barva textu bubliny vpravo" },
      { language: "en", label: "" },
    ],
    leftBubbleTextColor: [
      { language: "cs", label: "Barva textu bubliny vlevo" },
      { language: "en", label: "" },
    ],
    inputBackgroundColor: [
      { language: "cs", label: "Barva pozadí vstupu" },
      { language: "en", label: "" },
    ],
    inputTextColor: [
      { language: "cs", label: "Barva textu vstupu" },
      { language: "en", label: "" },
    ],
    inputSendColor: [
      { language: "cs", label: "Barva šipky odeslat" },
      { language: "en", label: "" },
    ],
  },
  appSearch: {
    title: [
      { language: "cs", label: "Vyhledávání v aplikaci" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    useDarkTheme: [
      { language: "cs", label: "Použít tmavé téma" },
      { language: "en", label: "" },
    ],
    backgroundColor: [
      { language: "cs", label: "Barva pozadí:" },
      { language: "en", label: "" },
    ],
    textColor: [
      { language: "cs", label: "Barva textu:" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Změnit nastavení" },
      { language: "en", label: "" },
    ],
  },
  sendNotification: {
    title: [
      { language: "cs", label: "Odeslat notifikaci" },
      { language: "en", label: "" },
    ],
    titleEmail: [
      { language: "cs", label: "Odeslat e-mail" },
      { language: "en", label: "" },
    ],
    titleSms: [
      { language: "cs", label: "Odeslat SMS" },
      { language: "en", label: "" },
    ],
    now: [
      { language: "cs", label: "nyní" },
      { language: "en", label: "" },
    ],
    scheduled: [
      { language: "cs", label: "plánovaně" },
      { language: "en", label: "" },
    ],
    all: [
      { language: "cs", label: "všem" },
      { language: "en", label: "" },
    ],
    notificationTitle: [
      { language: "cs", label: "Nadpis" },
      { language: "en", label: "" },
    ],
    notificationTitlePlaceholder: [
      {
        language: "cs",
        label:
          "Uveďte nadpis notifikační zprávy. Doporučujeme maximálně 40 znaků.",
      },
      { language: "en", label: "" },
    ],
    emailTitlePlaceholder: [
      {
        language: "cs",
        label: "Uveďte nadpis e-mailu.",
      },
      { language: "en", label: "" },
    ],
    smsTitlePlaceholder: [
      {
        language: "cs",
        label: "Uveďte nadpis SMS.",
      },
      { language: "en", label: "" },
    ],
    notificationSegments: [
      {
        language: "cs",
        label: "Cílové publikum",
      },
      { language: "en", label: "" },
    ],
    notificationSegmentsPlaceholder: [
      {
        language: "cs",
        label: "Vyberte, komu zprávu posíláte.",
      },
      { language: "en", label: "" },
    ],

    notificationText: [
      { language: "cs", label: "Zpráva" },
      { language: "en", label: "" },
    ],
    notificationTextPlaceholder: [
      { language: "cs", label: "Napište svou zprávu." },
      { language: "en", label: "" },
    ],
    notificationSchedule: [
      {
        language: "cs",
        label: "Odeslání",
      },
      { language: "en", label: "" },
    ],
    notificationSchedulePlaceholder: [
      {
        language: "cs",
        label: "Vyberte z možností.",
      },
      { language: "en", label: "" },
    ],

    notificationScheduleDate: [
      {
        language: "cs",
        label: "Plánovaný termín",
      },
      { language: "en", label: "" },
    ],
    notificationScheduleDatePlaceholder: [
      {
        language: "cs",
        label: "Vyberte přesný termín odeslání své plánované zprávy.",
      },
      { language: "en", label: "" },
    ],
    emailScheduleDatePlaceholder: [
      {
        language: "cs",
        label: "Vyberte přesný termín odeslání svého plánovaného e-mailu.",
      },
      { language: "en", label: "" },
    ],
    smsScheduleDatePlaceholder: [
      {
        language: "cs",
        label: "Vyberte přesný termín odeslání své plánované SMS zprávy.",
      },
      { language: "en", label: "" },
    ],
    notificationAction: [
      {
        language: "cs",
        label: "Akce zprávy",
      },
      { language: "en", label: "" },
    ],
    notificationActionPlaceholder: [
      {
        language: "cs",
        label: "Vyberte následnou akci z notifikační zprávy.",
      },
      { language: "en", label: "" },
    ],
    dropZonePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte pdf soubor.",
      },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte obrázek.",
      },
      { language: "en", label: "" },
    ],
    notificationImages: [
      {
        language: "cs",
        label: "Obrázky",
      },
      { language: "en", label: "" },
    ],
    notificationImagesPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte obrázky.",
      },
      { language: "en", label: "" },
    ],
    notificationAudio: [
      {
        language: "cs",
        label: "Audio",
      },
      { language: "en", label: "" },
    ],
    audioPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte audio.",
      },
      { language: "en", label: "" },
    ],
    notificationAudioPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte audio.",
      },
      { language: "en", label: "" },
    ],
    notificationVideo: [
      {
        language: "cs",
        label: "Video",
      },
      { language: "en", label: "" },
    ],
    videoPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte video.",
      },
      { language: "en", label: "" },
    ],
    notificationVideoPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte video.",
      },
      { language: "en", label: "" },
    ],
    emailActionPlaceholder: [
      {
        language: "cs",
        label: "Vyberte následnou akci z e-mailu.",
      },
      { language: "en", label: "" },
    ],
    smsActionPlaceholder: [
      {
        language: "cs",
        label: "Vyberte následnou akci z SMS zprávy.",
      },
      { language: "en", label: "" },
    ],

    noAction: [
      { language: "cs", label: "Žádná akce" },
      { language: "en", label: "" },
    ],
    isTest: [
      { language: "cs", label: "Poslat testovací zprávu" },
      { language: "en", label: "" },
    ],
    usePush: [
      { language: "cs", label: "Poslat Push notifikaci" },
      { language: "en", label: "" },
    ],
    useSms: [
      { language: "cs", label: "Poslat SMS" },
      { language: "en", label: "" },
    ],
    useEmail: [
      { language: "cs", label: "Poslat Email" },
      { language: "en", label: "" },
    ],
    testPhone: [
      { language: "cs", label: "Telefon" },
      { language: "en", label: "" },
    ],

    testEmail: [
      { language: "cs", label: "Testovací e-mail" },
      { language: "en", label: "" },
    ],
    testSms: [
      { language: "cs", label: "Testovací SMS" },
      { language: "en", label: "" },
    ],
    emailAddress: [
      { language: "cs", label: "E-mailová adresa" },
      { language: "en", label: "" },
    ],
    phoneNumber: [
      { language: "cs", label: "Telefonní číslo" },
      { language: "en", label: "" },
    ],
    emailAddressPlaceholder: [
      {
        language: "cs",
        label: "Zadejte adresu, na kterou obdržíte testovací e-mail.",
      },
      { language: "en", label: "" },
    ],
    phoneNumberPlaceholder: [
      {
        language: "cs",
        label:
          "Zadejte telefonní číslo, na které obdržíte testovací SMS zprávu.",
      },
      { language: "en", label: "" },
    ],
    imagesSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání obrázků...",
      },
      { language: "en", label: "" },
    ],
    audioSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání audio souborů...",
      },
      { language: "en", label: "" },
    ],
    videoSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání video souborů...",
      },
      { language: "en", label: "" },
    ],
    pushSending: [
      {
        language: "cs",
        label: "Probíhá odesílání notifikace...",
      },
      { language: "en", label: "" },
    ],
    send: [
      { language: "cs", label: "Odeslat notifikaci" },
      { language: "en", label: "" },
    ],
    noAction: [
      { language: "cs", label: "Bez akce" },
      { language: "en", label: "" },
    ],
    navigationAction: [
      { language: "cs", label: "Přesunout na jinou stránku" },
      { language: "en", label: "" },
    ],
    urlAction: [
      { language: "cs", label: "Otevřít webovou stránku mimo aplikaci" },
      { language: "en", label: "" },
    ],
    urlAction2: [
      { language: "cs", label: "Otevřít odkaz" },
      { language: "en", label: "" },
    ],
    phoneAction: [
      { language: "cs", label: "Vytočit telefonní číslo" },
      { language: "en", label: "" },
    ],
    emailAction: [
      { language: "cs", label: "Otevřít e-mail" },
      { language: "en", label: "" },
    ],
    smsAction: [
      { language: "cs", label: "Napsat SMS zprávu" },
      { language: "en", label: "" },
    ],
    webviewAction: [
      { language: "cs", label: "Otevřít webovou stránku uvnitř aplikace" },
      { language: "en", label: "" },
    ],
    chatAction: [
      { language: "cs", label: "Otevřít chat" },
      { language: "en", label: "" },
    ],
    mapGpsAction: [
      { language: "cs", label: "Otevřít mapu (zadat GPS souřadnice)" },
      { language: "en", label: "" },
    ],
    mapAddressAction: [
      { language: "cs", label: "Otevřít mapu (zadat adresu)" },
      { language: "en", label: "" },
    ],
    quizAction: [
      { language: "cs", label: "Spustit kvíz" },
      { language: "en", label: "" },
    ],
    mindfulnessAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessProAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessTouchAction: [
      { language: "cs", label: "Zahájit dechové cvičení" },
      { language: "en", label: "" },
    ],
    mapAction: [
      { language: "cs", label: "Otevřít interní mapu" },
      { language: "en", label: "" },
    ],
    pdfAction: [
      { language: "cs", label: "Zobrazit dokument PDF" },
      { language: "en", label: "" },
    ],
    videoAction: [
      { language: "cs", label: "Přehrát video" },
      { language: "en", label: "" },
    ],
    audioAction: [
      { language: "cs", label: "Přehrát zvuk" },
      { language: "en", label: "" },
    ],
    confirmText: [
      { language: "cs", label: "Odeslání zprávy proběhlo úspěšně." },
      { language: "en", label: "" },
    ],
    errorText: [
      {
        language: "cs",
        label: "Odeslání zprávy selhalo. Pošlete zprávu znovu.",
      },
      { language: "en", label: "" },
    ],
    confirmSendNext: [
      { language: "cs", label: "Poslat další" },
      { language: "en", label: "" },
    ],
    confirmHome: [
      { language: "cs", label: "Domů" },
      { language: "en", label: "" },
    ],
  },
  historyNotifications: {
    title: [
      { language: "cs", label: "Historie notifikací" },
      { language: "en", label: "" },
    ],
    titleEmailHistory: [
      { language: "cs", label: "Historie e-mailů" },
      { language: "en", label: "" },
    ],
    titleSmsHistory: [
      { language: "cs", label: "Historie odeslaných SMS zpráv" },
      { language: "en", label: "" },
    ],
    titlePushSheduled: [
      { language: "cs", label: "Naplánované notifikační zprávy" },
      { language: "en", label: "" },
    ],
    titleEmailSheduled: [
      { language: "cs", label: "Naplánované e-maily" },
      { language: "en", label: "" },
    ],
    titleSmsSheduled: [
      { language: "cs", label: "Naplánované SMS zprávy" },
      { language: "en", label: "" },
    ],
    resendTitle: [
      { language: "cs", label: "Opravdu si přejete notifikaci znovu odeslat?" },
      { language: "en", label: "" },
    ],
    resendText: [
      { language: "cs", label: "Notifikace bude znovu zaslána!!!" },
      { language: "en", label: "" },
    ],
    resendBack: [
      { language: "cs", label: "Zpět" },
      { language: "en", label: "" },
    ],
    resendSend: [
      { language: "cs", label: "Odeslat Notifikaci" },
      { language: "en", label: "" },
    ],
    contentButton: [
      { language: "cs", label: "Náhled oznámení" },
      { language: "en", label: "" },
    ],
    resendButton: [
      { language: "cs", label: "Odeslat znovu" },
      { language: "en", label: "" },
    ],
    infoButton: [
      { language: "cs", label: "Informace o notifikaci" },
      { language: "en", label: "" },
    ],
  },
  settingsNotifications: {
    title: [
      { language: "cs", label: "Nastavení notifikací" },
      { language: "en", label: "" },
    ],
    segments: [
      { language: "cs", label: "Nastavení segmentů notifikací" },
      { language: "en", label: "" },
    ],
    appearance: [
      { language: "cs", label: "Nastavení vzhledu notifikací" },
      { language: "en", label: "" },
    ],
    firebase: [
      { language: "cs", label: "Nastavení firebase projektu" },
      { language: "en", label: "" },
    ],
    timeThreshold: [
      { language: "cs", label: "Nastavení stáří notifikací" },
      { language: "en", label: "" },
    ],
    saveTimeThreshold: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat segment" },
      { language: "en", label: "" },
    ],
    remove: [
      { language: "cs", label: "Odebrat segment" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Změnit Nastavení segmentů notifikací" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    blurExtraLight: [
      { language: "cs", label: "Extra světlé" },
      { language: "en", label: "" },
    ],
    blurLight: [
      { language: "cs", label: "Světlé" },
      { language: "en", label: "" },
    ],
    blurDark: [
      { language: "cs", label: "Tmavé" },
      { language: "en", label: "" },
    ],
    blurExtraDark: [
      { language: "cs", label: "Extra tmavé" },
      { language: "en", label: "" },
    ],
    useBlurLight: [
      { language: "cs", label: "Použít rozmazání (světlé téma)" },
      { language: "en", label: "" },
    ],
    useBlurDark: [
      { language: "cs", label: "Použít rozmazání (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightIconColor: [
      { language: "cs", label: "Barva ikony (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkIconColor: [
      { language: "cs", label: "Barva ikony (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightTitleColor: [
      { language: "cs", label: "Barva nadpisu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkTitleColor: [
      { language: "cs", label: "Barva nadpisu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightTextColor: [
      { language: "cs", label: "Barva textu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkTextColor: [
      { language: "cs", label: "Barva textu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],

    lightAudioBackgroundColor: [
      { language: "cs", label: "Barva pozadí audia (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkAudioBackgroundColor: [
      { language: "cs", label: "Barva pozadí audia (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightAudioTextColor: [
      { language: "cs", label: "Barva textu audia (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkAudioTextColor: [
      { language: "cs", label: "Barva textu audia (tmavé téma)" },
      { language: "en", label: "" },
    ],

    lightVideoBackgroundColor: [
      { language: "cs", label: "Barva pozadí videa (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkVideoBackgroundColor: [
      { language: "cs", label: "Barva pozadí videa (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightVideoTextColor: [
      { language: "cs", label: "Barva textu videa (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkVideoTextColor: [
      { language: "cs", label: "Barva textu videa (tmavé téma)" },
      { language: "en", label: "" },
    ],

    lightHistoryBackgroundColor: [
      { language: "cs", label: "Barva pozadí (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkHistoryBackgroundColor: [
      { language: "cs", label: "Barva pozadí (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightHistoryItemColor: [
      { language: "cs", label: "Barva položek (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkHistoryItemColor: [
      { language: "cs", label: "Barva položek (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightHistoryTextColor: [
      { language: "cs", label: "Barva textu (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkHistoryTextColor: [
      { language: "cs", label: "Barva textu (tmavé téma)" },
      { language: "en", label: "" },
    ],
    lightHistoryCloseColor: [
      { language: "cs", label: "Barva křížku (světlé téma)" },
      { language: "en", label: "" },
    ],
    darkHistoryCloseColor: [
      { language: "cs", label: "Barva křížku (tmavé téma)" },
      { language: "en", label: "" },
    ],
    enableEmail: [
      { language: "cs", label: "Povolit odesílání e-mailu z aplikace" },
      { language: "en", label: "" },
    ],
    enableSms: [
      { language: "cs", label: "Povolit odesílání SMS z aplikace" },
      { language: "en", label: "" },
    ],
    enableEmailSms: [
      { language: "cs", label: "Nastavení e-mailu a SMS z aplikace" },
      { language: "en", label: "" },
    ],
    saveEnableEmailSms: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    saveAppearance: [
      { language: "cs", label: "Změnit Nastavení vzhledu notifikací" },
      { language: "en", label: "" },
    ],
  },
  notificationsRegisteredStreets: {
    title: [
      { language: "cs", label: "Nastavení ulic" },
      { language: "en", label: "" },
    ],
    segments: [
      { language: "cs", label: "Nastavení segmentů notifikací" },
      { language: "en", label: "" },
    ],
    import: [
      { language: "cs", label: "Import" },
      { language: "en", label: "" },
    ],
    dropText: [
      { language: "cs", label: "Zde nahrajte soubor" },
      { language: "en", label: "" },
    ],
    closeDropzone: [
      { language: "cs", label: "Zavřít" },
      { language: "en", label: "" },
    ],
    export: [
      { language: "cs", label: "Export" },
      { language: "en", label: "" },
    ],

    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
  },
  notificationsRegisteredUsers: {
    title: [
      { language: "cs", label: "Nastavení uživatelů" },
      { language: "en", label: "" },
    ],
    segments: [
      { language: "cs", label: "Nastavení segmentů notifikací" },
      { language: "en", label: "" },
    ],
    import: [
      { language: "cs", label: "Import" },
      { language: "en", label: "" },
    ],
    dropText: [
      { language: "cs", label: "Zde nahrajte soubor" },
      { language: "en", label: "" },
    ],
    closeDropzone: [
      { language: "cs", label: "Zavřít" },
      { language: "en", label: "" },
    ],
    export: [
      { language: "cs", label: "Export" },
      { language: "en", label: "" },
    ],

    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    appUsers: [
      { language: "cs", label: "Uživatelé aplikace" },
      { language: "en", label: "" },
    ],
    emailUsers: [
      { language: "cs", label: "Odběratelé emailu" },
      { language: "en", label: "" },
    ],
    phoneUsers: [
      { language: "cs", label: "Odběratelé sms" },
      { language: "en", label: "" },
    ],
    userEmail: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    userPhone: [
      { language: "cs", label: "Telefon" },
      { language: "en", label: "" },
    ],
    userName: [
      { language: "cs", label: "Jméno" },
      { language: "en", label: "" },
    ],
    userSurname: [
      { language: "cs", label: "Příjmení" },
      { language: "en", label: "" },
    ],
    userStreet: [
      { language: "cs", label: "Ulice" },
      { language: "en", label: "" },
    ],
  },
  appManagement: {
    title: [
      { language: "cs", label: "Spravujte své moduly" },
      { language: "en", label: "" },
    ],
    forms: [
      { language: "cs", label: "Výsledky formulářů" },
      { language: "en", label: "" },
    ],
    quizzes: [
      { language: "cs", label: "Kvízy a ankety" },
      { language: "en", label: "" },
    ],
    leaflets: [
      { language: "cs", label: "Zpravodaje a letáky" },
      { language: "en", label: "" },
    ],
    maps: [
      { language: "cs", label: "Interaktivní mapy" },
      { language: "en", label: "" },
    ],
    coupons: [
      { language: "cs", label: "Kupóny" },
      { language: "en", label: "" },
    ],
    paros: [
      { language: "cs", label: "Participační rozpočty" },
      { language: "en", label: "" },
    ],
    chat: [
      { language: "cs", label: "Online chat" },
      { language: "en", label: "" },
    ],
    accomodation: [
      { language: "cs", label: "Pro ubytovatele" },
      { language: "en", label: "" },
    ],
  },
  quizzesResultsAppearance: {
    title: [
      { language: "cs", label: "Správa kvízů" },
      { language: "en", label: "" },
    ],
    appearanceTitle: [
      { language: "cs", label: "Nastavení kvízových výsledků" },
      { language: "en", label: "" },
    ],
    quizzesTitle: [
      { language: "cs", label: "Kvízy" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí stránky:" },
      { language: "en", label: "" },
    ],
    lightBackgroundMenuColor: [
      { language: "cs", label: "Barva pozadí menu:" },
      { language: "en", label: "" },
    ],
    lightBackgroundGraphColor: [
      { language: "cs", label: "Barva pozadí grafu:" },
      { language: "en", label: "" },
    ],
    lightTextColor: [
      { language: "cs", label: "Barva textu:" },
      { language: "en", label: "" },
    ],
    lightTextMenuColor: [
      { language: "cs", label: "Barva textu menu:" },
      { language: "en", label: "" },
    ],
    lightPointGraphColor: [
      { language: "cs", label: "Barva bodu grafu:" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí:" },
      { language: "en", label: "" },
    ],
    darkBackgroundMenuColor: [
      { language: "cs", label: "Barva pozadí menu:" },
      { language: "en", label: "" },
    ],
    darkBackgroundGraphColor: [
      { language: "cs", label: "Barva pozadí grafu:" },
      { language: "en", label: "" },
    ],
    darkTextColor: [
      { language: "cs", label: "Barva textu:" },
      { language: "en", label: "" },
    ],
    darkTextMenuColor: [
      { language: "cs", label: "Barva textu menu:" },
      { language: "en", label: "" },
    ],
    darkPointGraphColor: [
      { language: "cs", label: "Barva bodu grafu:" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Změnit nastavení výsledků kvízů" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    results: [
      { language: "cs", label: "Výsledky" },
      { language: "en", label: "" },
    ],
    duplicate: [
      { language: "cs", label: "Duplikovat" },
      { language: "en", label: "" },
    ],
  },
  leaflets: {
    title: [
      { language: "cs", label: "Správa letáků" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    date: [
      { language: "cs", label: "Datum vytvoření" },
      { language: "en", label: "" },
    ],
    edit: [
      { language: "cs", label: "Editovat" },
      { language: "en", label: "" },
    ],
    new: [
      { language: "cs", label: "Vytvořit nový" },
      { language: "en", label: "" },
    ],
    back: [
      { language: "cs", label: "Zpět" },
      { language: "en", label: "" },
    ],
    copy: [
      { language: "cs", label: "Kopírovat odkaz na leták" },
      { language: "en", label: "" },
    ],
  },
  leaflet: {
    title: [
      { language: "cs", label: "Správa letáku" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    date: [
      { language: "cs", label: "Datum vytvoření" },
      { language: "en", label: "" },
    ],
    remove: [
      { language: "cs", label: "Odstranit" },
      { language: "en", label: "" },
    ],
    back: [
      { language: "cs", label: "Zpět" },
      { language: "en", label: "" },
    ],
    removeTitle: [
      { language: "cs", label: "Opravdu si přejete leták odstranit?" },
      { language: "en", label: "" },
    ],
    removeText: [
      { language: "cs", label: "Leták bude trvale ostraněn!!!" },
      { language: "en", label: "" },
    ],
    removeButton: [
      { language: "cs", label: "Odstranit leták" },
      { language: "en", label: "" },
    ],
    removePageTitle: [
      { language: "cs", label: "Opravdu si přejete stránku odstranit?" },
      { language: "en", label: "" },
    ],
    removePageText: [
      { language: "cs", label: "Stránka bude trvale ostraněna!!!" },
      { language: "en", label: "" },
    ],
    removePageButton: [
      { language: "cs", label: "Odstranit stránku" },
      { language: "en", label: "" },
    ],
    removeItemTitle: [
      { language: "cs", label: "Opravdu si přejete položku odstranit?" },
      { language: "en", label: "" },
    ],
    removeItemText: [
      { language: "cs", label: "Položka bude trvale ostraněna!!!" },
      { language: "en", label: "" },
    ],
    removeItemButton: [
      { language: "cs", label: "Odstranit položku" },
      { language: "en", label: "" },
    ],
    newPage: [
      { language: "cs", label: "Vytvořit novou stránku" },
      { language: "en", label: "" },
    ],
    newItem: [
      { language: "cs", label: "Vytvořit novou položku" },
      { language: "en", label: "" },
    ],
    createPage: [
      { language: "cs", label: "Vytvořit" },
      { language: "en", label: "" },
    ],
    deleteImage: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],
    selectImage: [
      { language: "cs", label: "Vybrat" },
      { language: "en", label: "" },
    ],
    uploadPlaceholder: [
      { language: "cs", label: "Sem klikněte, nebo přetáhněte obrázek." },
      { language: "en", label: "" },
    ],
    editorBack: [
      { language: "cs", label: "Zpět" },
      { language: "en", label: "" },
    ],
    editorReset: [
      { language: "cs", label: "Reset" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    titleEditor: [
      { language: "cs", label: "Editor nadpisu" },
      { language: "en", label: "" },
    ],
    textEditor: [
      { language: "cs", label: "Editor popisku" },
      { language: "en", label: "" },
    ],
    title: [
      { language: "cs", label: "Editovat nadpis" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Editovat text" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "Editovat obrázek" },
      { language: "en", label: "" },
    ],
    link: [
      { language: "cs", label: "Editovat odkaz" },
      { language: "en", label: "" },
    ],
    linkText: [
      { language: "cs", label: "Text odkazu" },
      { language: "en", label: "" },
    ],
    linkUrl: [
      { language: "cs", label: "Adresa odkazu" },
      { language: "en", label: "" },
    ],
    saveLink: [
      { language: "cs", label: "Uložit odkaz" },
      { language: "en", label: "" },
    ],
    item: [
      { language: "cs", label: "Položka" },
      { language: "en", label: "" },
    ],
    backgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    textColor: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    pointsColor: [
      { language: "cs", label: "Barva oblasti" },
      { language: "en", label: "" },
    ],
    useWebview: [
      {
        language: "cs",
        label: "K otevření odkazu použít integrovaný prohlížeč",
      },
      { language: "en", label: "" },
    ],
    backgroundImage: [
      { language: "cs", label: "Obrázek na pozadí" },
      { language: "en", label: "" },
    ],
    addBackgroundImage: [
      { language: "cs", label: "Přidát " },
      { language: "en", label: "" },
    ],
  },
  quizSettingsBaseParametres: {
    title: [
      { language: "cs", label: "Základní parametry" },
      { language: "en", label: "" },
    ],
    questionTextSize: [
      { language: "cs", label: "Velikost textu otázky" },
      { language: "en", label: "" },
    ],
    answerTextSize: [
      { language: "cs", label: "Velikost textu odpovědi" },
      { language: "en", label: "" },
    ],
    selectedAnswerTextSize: [
      { language: "cs", label: "Velikost textu vybrané odpovědi" },
    ],
    openHistory: [
      { language: "cs", label: "Po dokončení kvízu otevřít historii." },
      { language: "en", label: "" },
    ],
    showScore: [
      { language: "cs", label: "Zobrazit skóre." },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    lightBackgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    lightQuestionTextColor: [
      { language: "cs", label: "Barva textu otázky" },
      { language: "en", label: "" },
    ],
    lightAnswerTextColor: [
      { language: "cs", label: "Barva textu odpovědi" },
      { language: "en", label: "" },
    ],
    lightAnswerBackgroundColor: [
      { language: "cs", label: "Barva pozadí odpovědi" },
      { language: "en", label: "" },
    ],
    lightSelectedAnswerTextColor: [
      { language: "cs", label: "Barva textu vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    lightSelectedAnswerBackgroundColor: [
      { language: "cs", label: "Barva pozadí vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    lightTextBackColor: [
      { language: "cs", label: "Barva textu zpět" },
      { language: "en", label: "" },
    ],
    lightBackgroundBackColor: [
      { language: "cs", label: "Barva pozadí zpět" },
      { language: "en", label: "" },
    ],
    lightTextNextColor: [
      { language: "cs", label: "Barva textu další" },
      { language: "en", label: "" },
    ],
    lightBackgroundNext: [
      { language: "cs", label: "Barva pozadí další" },
      { language: "en", label: "" },
    ],
    lightTextDoneColor: [
      { language: "cs", label: "Barva textu hotovo" },
      { language: "en", label: "" },
    ],
    lightBackgroundDone: [
      { language: "cs", label: "Barva pozadí hotovo" },
      { language: "en", label: "" },
    ],
    lightTextResultColor: [
      { language: "cs", label: "Barva textu výsledku" },
      { language: "en", label: "" },
    ],
    lightBackgroundResult: [
      { language: "cs", label: "Barva pozadí výsledku" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    darkBackgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    darkQuestionTextColor: [
      { language: "cs", label: "Barva textu otázky" },
      { language: "en", label: "" },
    ],
    darkAnswerTextColor: [
      { language: "cs", label: "Barva textu odpovědi" },
      { language: "en", label: "" },
    ],
    darkAnswerBackgroundColor: [
      { language: "cs", label: "Barva pozadí odpovědi" },
      { language: "en", label: "" },
    ],
    darkSelectedAnswerTextColor: [
      { language: "cs", label: "Barva textu vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    darkSelectedAnswerBackgroundColor: [
      { language: "cs", label: "Barva pozadí vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    darkTextBackColor: [
      { language: "cs", label: "Barva textu zpět" },
      { language: "en", label: "" },
    ],
    darkBackgroundBackColor: [
      { language: "cs", label: "Barva pozadí zpět" },
      { language: "en", label: "" },
    ],
    darkTextNextColor: [
      { language: "cs", label: "Barva textu další" },
      { language: "en", label: "" },
    ],
    darkBackgroundNext: [
      { language: "cs", label: "Barva pozadí další" },
      { language: "en", label: "" },
    ],
    darkTextDoneColor: [
      { language: "cs", label: "Barva textu hotovo" },
      { language: "en", label: "" },
    ],
    darkBackgroundDone: [
      { language: "cs", label: "Barva pozadí hotovo" },
      { language: "en", label: "" },
    ],
    darkTextResultColor: [
      { language: "cs", label: "Barva textu výsledku" },
      { language: "en", label: "" },
    ],
    darkBackgroundResult: [
      { language: "cs", label: "Barva pozadí výsledku" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Změnit grafické nastavení výsledků kvízu" },
      { language: "en", label: "" },
    ],
  },
  quizSettingsQuestions: {
    title: [
      { language: "cs", label: "Otázky" },
      { language: "en", label: "" },
    ],
    question: [
      { language: "cs", label: "Otázka" },
      { language: "en", label: "" },
    ],
    answer: [
      { language: "cs", label: "Odpověd" },
      { language: "en", label: "" },
    ],
    addQuestion: [
      { language: "cs", label: "Přidat otázku" },
      { language: "en", label: "" },
    ],
    removeQuestion: [
      { language: "cs", label: "Smazat otázku" },
      { language: "en", label: "" },
    ],
    addAnswer: [
      { language: "cs", label: "Přidat odpověd" },
      { language: "en", label: "" },
    ],
    removeAnswer: [
      { language: "cs", label: "Smazat odpověd" },
      { language: "en", label: "" },
    ],
    answerPoints: [
      { language: "cs", label: "Bodové ohodnocení" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit otázky" },
      { language: "en", label: "" },
    ],
  },
  quizSettingsResults: {
    title: [
      { language: "cs", label: "Nastavení výsledků" },
      { language: "en", label: "" },
    ],
    result: [
      { language: "cs", label: "Výsledek" },
      { language: "en", label: "" },
    ],
    answer: [
      { language: "cs", label: "Odpověd" },
      { language: "en", label: "" },
    ],
    addResult: [
      { language: "cs", label: "Přidat výsledek" },
      { language: "en", label: "" },
    ],
    removeResult: [
      { language: "cs", label: "Smazat výsledek" },
      { language: "en", label: "" },
    ],
    scoreFrom: [
      { language: "cs", label: "Skóre od:" },
      { language: "en", label: "" },
    ],
    scoreTo: [
      { language: "cs", label: "Skóre do:" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit výsledky" },
      { language: "en", label: "" },
    ],
  },
  quizResults: {
    title: [
      { language: "cs", label: "Výsledky kvízu" },
      { language: "en", label: "" },
    ],
    export: [
      { language: "cs", label: "export" },
      { language: "en", label: "" },
    ],
    user: [
      { language: "cs", label: "Uživatel" },
      { language: "en", label: "" },
    ],
    score: [
      { language: "cs", label: "Skóre" },
      { language: "en", label: "" },
    ],
    date: [
      { language: "cs", label: "Datum" },
      { language: "en", label: "" },
    ],
    results: [
      { language: "cs", label: "Výsledky" },
      { language: "en", label: "" },
    ],
    removeRestore: [
      { language: "cs", label: "Smazat/Obnovit" },
      { language: "en", label: "" },
    ],
  },
  appSettings: {
    title: [
      { language: "cs", label: "Nastavení aplikace" },
      { language: "en", label: "" },
    ],
    baseParameters: [
      { language: "cs", label: "Základní parametry" },
      { language: "en", label: "" },
    ],
    studioAppSettings: [
      { language: "cs", label: "Nastavení studia" },
      { language: "en", label: "" },
    ],
    languages: [
      { language: "cs", label: "Jazyky" },
      { language: "en", label: "" },
    ],
    permissions: [
      { language: "cs", label: "Práva" },
      { language: "en", label: "" },
    ],
    icon: [
      { language: "cs", label: "Ikona" },
      { language: "en", label: "" },
    ],
    splash: [
      { language: "cs", label: "Úvodní obrázek" },
      { language: "en", label: "" },
    ],
    welcome: [
      { language: "cs", label: "Úvodní jednorázová informace" },
      { language: "en", label: "" },
    ],
    systemMessages: [
      { language: "cs", label: "Systémové hlášky" },
      { language: "en", label: "" },
    ],
    webView: [
      { language: "cs", label: "Vložený web" },
      { language: "en", label: "" },
    ],
    login: [
      { language: "cs", label: "Přihlášení" },
      { language: "en", label: "" },
    ],
    likedMarker: [
      { language: "cs", label: "Oblíbené místo v mapě" },
      { language: "en", label: "" },
    ],
    userNotifications: [
      { language: "cs", label: "Uživatelské notifikace" },
      { language: "en", label: "" },
    ],
    appSearch: [
      { language: "cs", label: "Vyhledávání v aplikaci" },
      { language: "en", label: "" },
    ],
    eshop: [
      { language: "cs", label: "Eshop" },
      { language: "en", label: "" },
    ],
    coupon: [
      { language: "cs", label: "Kupón" },
      { language: "en", label: "" },
    ],
    notifications: [
      { language: "cs", label: "Nastavení notifikací" },
      { language: "en", label: "" },
    ],
    paid: [
      { language: "cs", label: "Placené sekce" },
      { language: "en", label: "" },
    ],
    admin: [
      { language: "cs", label: "Administrátorská sekce" },
      { language: "en", label: "" },
    ],
    map: [
      { language: "cs", label: "Mapy" },
      { language: "en", label: "" },
    ],
    map2: [
      { language: "cs", label: "Interaktivní mapy" },
      { language: "en", label: "" },
    ],
    ota: [
      { language: "cs", label: "OTA" },
      { language: "en", label: "" },
    ],
    delete: [
      { language: "cs", label: "Odstranit" },
      { language: "en", label: "" },
    ],
    export: [
      { language: "cs", label: "Export" },
      { language: "en", label: "" },
    ],
  },
  appBaseParametres: {
    title: [
      { language: "cs", label: "Základní nastavení" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název aplikace" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Název text" },
      { language: "en", label: "" },
    ],
    bundleIdIos: [
      { language: "cs", label: "Bundle id ios" },
      { language: "en", label: "" },
    ],
    bundleIdAndroid: [
      { language: "cs", label: "Bundle id android" },
      { language: "en", label: "" },
    ],
    appType: [
      { language: "cs", label: "Druh aplikace" },
      { language: "en", label: "" },
    ],
    voiceOver: [
      { language: "cs", label: "Použít mluvený komentář" },
      { language: "en", label: "" },
    ],
    notifications: [
      { language: "cs", label: "Použít notifikační zprávy" },
      { language: "en", label: "" },
    ],
    notificationsRegistered: [
      {
        language: "cs",
        label: "Použít notifikační zprávy pro registrované uživatele",
      },
      { language: "en", label: "" },
    ],
    dataNotifications: [
      { language: "cs", label: "Použít datové notifikační zprávy" },
      { language: "en", label: "" },
    ],
    geolocation: [
      { language: "cs", label: "Použít geolokační zprávy" },
      { language: "en", label: "" },
    ],
    geolocationInterval: [
      { language: "cs", label: "Interval měření polohy" },
      { language: "en", label: "" },
    ],
    welcomeScreen: [
      { language: "cs", label: "Použít uvítací stránku" },
      { language: "en", label: "" },
    ],
    login: [
      { language: "cs", label: "Použít přihlášení" },
      { language: "en", label: "" },
    ],
    registration: [
      { language: "cs", label: "Povolit registraci" },
      { language: "en", label: "" },
    ],
    cache: [
      { language: "cs", label: "Použít cache" },
      { language: "en", label: "" },
    ],
    languageSelectScreen: [
      { language: "cs", label: "Použít výběr jazyka" },
      { language: "en", label: "" },
    ],
    paid: [
      { language: "cs", label: "Placené sekce" },
      { language: "en", label: "" },
    ],
    notificationAction: [
      { language: "cs", label: "Použít akci notifikace po jejím otevření" },
      { language: "en", label: "" },
    ],
    coupon: [
      { language: "cs", label: "Použít kupóny v aplikaci" },
      { language: "en", label: "" },
    ],
    useCouponsWithMaps: [
      { language: "cs", label: "Použít kupóny společně s mapami" },
      { language: "en", label: "" },
    ],
    richText: [
      { language: "cs", label: "Použít formátováný text v notifikacích" },
      { language: "en", label: "" },
    ],
    appExtras: [
      { language: "cs", label: "Použít doplňkové moduly" },
      { language: "en", label: "" },
    ],
    accomodation: [
      { language: "cs", label: "Použít modul pro ubytovatele" },
      { language: "en", label: "" },
    ],
    search: [
      { language: "cs", label: "Použít fulltextové vyhledávání " },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    lightPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí" },
      { language: "en", label: "" },
    ],
    lightSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    darkPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí" },
      { language: "en", label: "" },
    ],
    darkSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
    emailNotifications: [
      { language: "cs", label: "Použít odesílání e-mailů" },
      { language: "en", label: "" },
    ],
    smsNotifications: [
      { language: "cs", label: "Použít odesílání sms" },
      { language: "en", label: "" },
    ],
  },
  appStudioSettings: {
    title: [
      { language: "cs", label: "Nastavení studia" },
      { language: "en", label: "" },
    ],
    showDownloadedStatistics: [
      { language: "cs", label: "Zobrazit statistiky stažení" },
      { language: "en", label: "" },
    ],
    firstBannerTitle: [
      { language: "cs", label: "První banner" },
      { language: "en", label: "" },
    ],
    secondBannerTitle: [
      { language: "cs", label: "Druhý banner" },
      { language: "en", label: "" },
    ],
    thirdBannerTitle: [
      { language: "cs", label: "Třetí banner" },
      { language: "en", label: "" },
    ],
    editorTitle: [
      { language: "cs", label: "Nadpis" },
      { language: "en", label: "" },
    ],
    editorText: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    editorImage: [
      { language: "cs", label: "Obrázek" },
      { language: "en", label: "" },
    ],
    filePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte soubor",
      },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
  },
  appLanguages: {
    title: [
      { language: "cs", label: "Jazykové mutace" },
      { language: "en", label: "" },
    ],
    newLanguage: [
      { language: "cs", label: "Přidat nový jazyk" },
      { language: "en", label: "" },
    ],
    enable: [
      { language: "cs", label: "Povolit" },
      { language: "en", label: "" },
    ],
  },
  appPermissions: {
    title: [
      { language: "cs", label: "Práva aplikace" },
      { language: "en", label: "" },
    ],
    location: [
      { language: "cs", label: "Poloha" },
      { language: "en", label: "" },
    ],
    gallery: [
      { language: "cs", label: "Galerie" },
      { language: "en", label: "" },
    ],
    microphone: [
      { language: "cs", label: "Mikrofon" },
      { language: "en", label: "" },
    ],
    cam: [
      { language: "cs", label: "Fotoaparát" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu práv" },
      { language: "en", label: "" },
    ],
  },
  appIcon: {
    title: [
      { language: "cs", label: "Ikona aplikace" },
      { language: "en", label: "" },
    ],
  },
  appMaps: {
    title: [
      { language: "cs", label: "Mapy" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    map: [
      { language: "cs", label: "Mapa" },
      { language: "en", label: "" },
    ],
    placesCoupons: [
      { language: "cs", label: "Lokace kupónů" },
      { language: "en", label: "" },
    ],
    newMap: [
      { language: "cs", label: "Nová mapa" },
      { language: "en", label: "" },
    ],
  },
  placesCoupons: {
    title: [
      { language: "cs", label: "Lokace kupónů" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
  },
  couponAdmin: {
    title: [
      { language: "cs", label: "Správa kupónů" },
      { language: "en", label: "" },
    ],
    couponEditor: [
      { language: "cs", label: "Editor kupónů" },
      { language: "en", label: "" },
    ],
    couponList: [
      { language: "cs", label: "Seznam kupónů" },
      { language: "en", label: "" },
    ],
    prefix: [
      { language: "cs", label: "Prefix kódu" },
      { language: "en", label: "" },
    ],
    savePrefix: [
      { language: "cs", label: "Uložit prefix kódu" },
      { language: "en", label: "" },
    ],
  },
  couponList: {
    title: [
      { language: "cs", label: "Seznam kupónů" },
      { language: "en", label: "" },
    ],
    useExpiration: [
      { language: "cs", label: "Použít expiraci" },
      { language: "en", label: "" },
    ],
    contentButton: [
      { language: "cs", label: "Náhled kupónu" },
      { language: "en", label: "" },
    ],
    editButton: [
      { language: "cs", label: "Editovat kupón" },
      { language: "en", label: "" },
    ],
    closeModal: [
      { language: "cs", label: "Zavřit" },
      { language: "en", label: "" },
    ],
    openedCount: [
      { language: "cs", label: "Počet otevření: " },
      { language: "en", label: "" },
    ],
    couponInfo: [
      { language: "cs", label: "Základní informace" },
      { language: "en", label: "" },
    ],
    attachmentsTitle: [
      { language: "cs", label: "Přílohy" },
      { language: "en", label: "" },
    ],
    textTitle: [
      { language: "cs", label: "Nadpis kupónu" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Popisek kupónu" },
      { language: "en", label: "" },
    ],
    code: [
      { language: "cs", label: "Kód kupónu" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "Obrázek kupónu" },
      { language: "en", label: "" },
    ],
    titlePlaceholder: [
      { language: "cs", label: "Zde zadejte nadpis kupónu" },
      { language: "en", label: "" },
    ],
    textPlaceholder: [
      { language: "cs", label: "Zde zadejte popisek kupónu" },
      { language: "en", label: "" },
    ],
    codePlaceholder: [
      { language: "cs", label: "Zde zadejte kód kupónu" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "Zde nahrajte obrázek kupónu" },
      { language: "en", label: "" },
    ],
    expirationTime: [
      { language: "cs", label: "Čas expirace" },
      { language: "en", label: "" },
    ],
    expiration: [
      { language: "cs", label: "Platnost" },
      { language: "en", label: "" },
    ],
    delete: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],
    edit: [
      { language: "cs", label: "Editovat" },
      { language: "en", label: "" },
    ],
    copy: [
      { language: "cs", label: "Kopirovat číslo kuponu" },
      { language: "en", label: "" },
    ],
    editTitle: [
      { language: "cs", label: "Editace kupónu" },
      { language: "en", label: "" },
    ],
    editSave: [
      { language: "cs", label: "Uložit změny" },
      { language: "en", label: "" },
    ],
    activeFrom: [
      { language: "cs", label: "Aktivní od" },
      { language: "en", label: "" },
    ],
    activeTo: [
      { language: "cs", label: "Aktivní do" },
      { language: "en", label: "" },
    ],
  },
  placeCoupons: {
    title: [
      { language: "cs", label: "Editace kupónů" },
      { language: "en", label: "" },
    ],
    couponInfo: [
      { language: "cs", label: "Základní informace" },
      { language: "en", label: "" },
    ],
    attachmentsTitle: [
      { language: "cs", label: "Přílohy" },
      { language: "en", label: "" },
    ],
    textTitle: [
      { language: "cs", label: "Nadpis kupónu" },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Popisek kupónu" },
      { language: "en", label: "" },
    ],
    code: [
      { language: "cs", label: "Kód kupónu" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "Obrázek kupónu" },
      { language: "en", label: "" },
    ],
    titlePlaceholder: [
      { language: "cs", label: "Zde zadejte nadpis kupónu" },
      { language: "en", label: "" },
    ],
    textPlaceholder: [
      { language: "cs", label: "Zde zadejte popisek kupónu" },
      { language: "en", label: "" },
    ],
    codePlaceholder: [
      { language: "cs", label: "Zde zadejte kód kupónu" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "Zde nahrajte obrázek kupónu" },
      { language: "en", label: "" },
    ],
    useExpiration: [
      { language: "cs", label: "Použít expiraci" },
      { language: "en", label: "" },
    ],
    expirationTime: [
      { language: "cs", label: "Čas expirace" },
      { language: "en", label: "" },
    ],
    expiration: [
      { language: "cs", label: "Platnost" },
      { language: "en", label: "" },
    ],
    openedCount: [
      { language: "cs", label: "Počet otevření" },
      { language: "en", label: "" },
    ],
    delete: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],
    edit: [
      { language: "cs", label: "Editovat" },
      { language: "en", label: "" },
    ],
    copy: [
      { language: "cs", label: "Kopirovat číslo kuponu" },
      { language: "en", label: "" },
    ],
    editTitle: [
      { language: "cs", label: "Editace kupónu" },
      { language: "en", label: "" },
    ],
    editSave: [
      { language: "cs", label: "Uložit změny" },
      { language: "en", label: "" },
    ],
    activeFrom: [
      { language: "cs", label: "Aktivní od" },
      { language: "en", label: "" },
    ],
    activeTo: [
      { language: "cs", label: "Aktivní do" },
      { language: "en", label: "" },
    ],
    create: [
      { language: "cs", label: "Vytvořit kupón" },
      { language: "en", label: "" },
    ],
    coupons: [
      { language: "cs", label: "Kupóny" },
      { language: "en", label: "" },
    ],
    prefix: [
      { language: "cs", label: "Prefix kódu" },
      { language: "en", label: "" },
    ],
    createPrefix: [
      { language: "cs", label: "Uložit prefix kódu" },
      { language: "en", label: "" },
    ],
    preview: [
      { language: "cs", label: "Náhled kupónu" },
      { language: "en", label: "" },
    ],
  },
  notificationsGeolocationPushZones: {
    title: [
      { language: "cs", label: "Geololační zóny" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    zone: [
      { language: "cs", label: "Zóna" },
      { language: "en", label: "" },
    ],
    newZone: [
      { language: "cs", label: "Nová zóna" },
      { language: "en", label: "" },
    ],
  },
  map: {
    title: [
      { language: "cs", label: "Mapa" },
      { language: "en", label: "" },
    ],
    markerCode: [
      { language: "cs", label: "Kód místa" },
      { language: "en", label: "" },
    ],
    remove: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],
  },
  mapBasicParameters: {
    title: [
      { language: "cs", label: "Základní parametry" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    lightClusterBackgroundColor: [
      { language: "cs", label: "Vybrat barvu pozadí mapového shluku" },
      { language: "en", label: "" },
    ],
    lightClusterTextColor: [
      { language: "cs", label: "Vybrat barvu textu mapového shluku" },
      { language: "en", label: "" },
    ],
    lightPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí stránky" },
      { language: "en", label: "" },
    ],
    lightSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu stránky" },
      { language: "en", label: "" },
    ],
    lightTextColor: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    useBack: [
      { language: "cs", label: "Zobrazit v mapě šipku zpět" },
      { language: "en", label: "" },
    ],
    importSegments: [
      { language: "cs", label: "Importovat segmenty pro notifikační zprávy" },
      { language: "en", label: "" },
    ],
    useFavorite: [
      { language: "cs", label: "Přidat označení oblíbeného místa" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    darkClusterBackgroundColor: [
      { language: "cs", label: "Barva pozadí bodu mapy" },
      { language: "en", label: "" },
    ],
    darkClusterTextColor: [
      { language: "cs", label: "Barva textu v bodu mapy" },
      { language: "en", label: "" },
    ],
    darkPrimaryColor: [
      { language: "cs", label: "Výchozí barva pozadí stránky" },
      { language: "en", label: "" },
    ],
    darkSecondaryColor: [
      { language: "cs", label: "Výchozí barva textu stránky" },
      { language: "en", label: "" },
    ],
    darkTextColor: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení mapy" },
      { language: "en", label: "" },
    ],
  },
  mapMarkerTypes: {
    title: [
      { language: "cs", label: "Typy míst" },
      { language: "en", label: "" },
    ],
    textPlaceholder: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "vložit obrázek" },
      { language: "en", label: "" },
    ],
    latitude: [
      { language: "cs", label: "Zeměpisná šířka" },
      { language: "en", label: "" },
    ],
    longitude: [
      { language: "cs", label: "Zeměpisná délka" },
      { language: "en", label: "" },
    ],
    appZoom: [
      { language: "cs", label: "Zoom v aplikaci" },
      { language: "en", label: "" },
    ],
    studioZoom: [
      { language: "cs", label: "Zoom ve studiu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat typ" },
      { language: "en", label: "" },
    ],
    addEmail: [
      { language: "cs", label: "Přidat e-maily" },
      { language: "en", label: "" },
    ],
    emails: [
      { language: "cs", label: "E-maily" },
      { language: "en", label: "" },
    ],
    noEmails: [
      { language: "cs", label: "Žadne E-maily nejsou" },
      { language: "en", label: "" },
    ],
    closeModal: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
  },
  mapMarkerStates: {
    title: [
      { language: "cs", label: "Stavy míst" },
      { language: "en", label: "" },
    ],
    textPlaceholder: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    color: [
      { language: "cs", label: "Vybrat barvu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Potvrdit změnu nastavení" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat stav" },
      { language: "en", label: "" },
    ],
  },
  mapSettingsData: {
    title: [
      { language: "cs", label: "Mapová data" },
      { language: "en", label: "" },
    ],
    dropText: [
      {
        language: "cs",
        label: "Nahrát tabulku s mapovými podklady",
      },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Stáhnout tabulku s mapovými podklady" },
      { language: "en", label: "" },
    ],
    closeDropzone: [
      { language: "cs", label: "Zavřit" },
      { language: "en", label: "" },
    ],
    import: [
      { language: "cs", label: "Import mapy" },
      { language: "en", label: "" },
    ],
  },
  newQuiz: {
    title: [
      { language: "cs", label: "Vytvoření nového kvízu" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit nový kvíz" },
      { language: "en", label: "" },
    ],
  },
  newLeaflet: {
    title: [
      { language: "cs", label: "Vytvoření nového letáku" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit nový leták" },
      { language: "en", label: "" },
    ],
  },
  newScreen: {
    title: [
      { language: "cs", label: "Vytvoření nové stránky" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit novou stránku" },
      { language: "en", label: "" },
    ],
  },
  appExtras: {
    title: [
      { language: "cs", label: "Doplňkové moduly" },
      { language: "en", label: "" },
    ],
    mindfulness: [
      { language: "cs", label: "Základní dechová cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessPro: [
      { language: "cs", label: "Pokročilejší dechová cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessTouch: [
      { language: "cs", label: "Dotyková dechová cvičení" },
      { language: "en", label: "" },
    ],
  },
  appStatistics: {
    title: [
      { language: "cs", label: "Sledujte statistiky své mobilní aplikace" },
      { language: "en", label: "" },
    ],
    sms: [
      { language: "cs", label: "Odeslané sms" },
      { language: "en", label: "" },
    ],
    email: [
      { language: "cs", label: "Odeslané emaily" },
      { language: "en", label: "" },
    ],
    appDownload: [
      { language: "cs", label: "Počet stažení aplikace" },
      { language: "en", label: "" },
    ],
    app: [
      { language: "cs", label: "Počet otevření aplikace" },
      { language: "en", label: "" },
    ],
    screens: [
      { language: "cs", label: "Stránky" },
      { language: "en", label: "" },
    ],
    components: [
      { language: "cs", label: "Komponenty" },
      { language: "en", label: "" },
    ],
    users: [
      { language: "cs", label: "Uživatelé" },
      { language: "en", label: "" },
    ],
    notifications: [
      { language: "cs", label: "Notifikace" },
      { language: "en", label: "" },
    ],
  },
  appUsers: {
    title: [
      { language: "cs", label: "Uživatelé aplikací" },
      { language: "en", label: "" },
    ],
    newUser: [
      { language: "cs", label: "Vytvořit nového uživatele" },
      { language: "en", label: "" },
    ],
    subject: [
      { language: "cs", label: "Subjekt" },
      { language: "en", label: "" },
    ],
    email: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    permission: [
      { language: "cs", label: "Oprávnění" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    verification: [
      { language: "cs", label: "Verifikace" },
      { language: "en", label: "" },
    ],
    verificationButton: [
      { language: "cs", label: "Verifikovat" },
      { language: "en", label: "" },
    ],
  },
  newAppUser: {
    title: [
      { language: "cs", label: "Vytvoření nového uživatele" },
      { language: "en", label: "" },
    ],
    email: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    user: [
      { language: "cs", label: "user" },
      { language: "en", label: "" },
    ],
    admin: [
      { language: "cs", label: "admin" },
      { language: "en", label: "" },
    ],
    root: [
      { language: "cs", label: "root" },
      { language: "en", label: "" },
    ],
    password: [
      { language: "cs", label: "Heslo" },
      { language: "en", label: "" },
    ],
    repeatPassword: [
      { language: "cs", label: "Heslo znovu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit nového uživatele" },
      { language: "en", label: "" },
    ],
  },
  appUser: {
    title: [
      { language: "cs", label: "Uživatel aplikace" },
      { language: "en", label: "" },
    ],
    email: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    user: [
      { language: "cs", label: "user" },
      { language: "en", label: "" },
    ],
    admin: [
      { language: "cs", label: "admin" },
      { language: "en", label: "" },
    ],
    root: [
      { language: "cs", label: "root" },
      { language: "en", label: "" },
    ],
    password: [
      { language: "cs", label: "Heslo" },
      { language: "en", label: "" },
    ],
    repeatPassword: [
      { language: "cs", label: "Heslo znovu" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Změnit údaje uživatele" },
      { language: "en", label: "" },
    ],
    changePassword: [
      { language: "cs", label: "Změnit heslo" },
      { language: "en", label: "" },
    ],
  },
  statisticsSms: {
    title: [
      { language: "cs", label: "Statistika odeslaných sms" },
      { language: "en", label: "" },
    ],
    year: [
      { language: "cs", label: "Rok" },
      { language: "en", label: "" },
    ],
    month: [
      { language: "cs", label: "Měsíc" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Počet" },
      { language: "en", label: "" },
    ],
  },
  statisticsEmail: {
    title: [
      { language: "cs", label: "Statistika odeslaných emailů" },
      { language: "en", label: "" },
    ],
    year: [
      { language: "cs", label: "Rok" },
      { language: "en", label: "" },
    ],
    month: [
      { language: "cs", label: "Měsíc" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Počet" },
      { language: "en", label: "" },
    ],
  },
  statisticsAppDownload: {
    title: [
      { language: "cs", label: "Statistika stažení aplikace" },
      { language: "en", label: "" },
    ],
    appStoreCount: [
      { language: "cs", label: "App Store" },
      { language: "en", label: "" },
    ],
    googlePlayCount: [
      { language: "cs", label: "Google Play" },
      { language: "en", label: "" },
    ],
  },
  statisticsAppUsage: {
    title: [
      { language: "cs", label: "Statistika otevření aplikace" },
      { language: "en", label: "" },
    ],
    all: [
      { language: "cs", label: "Vše" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Otevření celkem" },
      { language: "en", label: "" },
    ],
    days: [
      { language: "cs", label: "Návštěvnost aplikace" },
      { language: "en", label: "" },
    ],
  },
  statisticsScreensUsage: {
    title: [
      { language: "cs", label: "Statistika stránek" },
      { language: "en", label: "" },
    ],
    all: [
      { language: "cs", label: "Vše" },
      { language: "en", label: "" },
    ],
    allOpenedCount: [
      { language: "cs", label: "Otevření celkem" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Stránek celkem" },
      { language: "en", label: "" },
    ],
    top: [
      { language: "cs", label: "Nejnavštěvovanější stránky" },
      { language: "en", label: "" },
    ],
    days: [
      { language: "cs", label: "Návštěvnost stránek" },
      { language: "en", label: "" },
    ],
  },
  statisticsComponentsUsage: {
    title: [
      { language: "cs", label: "Statistika komponent" },
      { language: "en", label: "" },
    ],
    all: [
      { language: "cs", label: "Vše" },
      { language: "en", label: "" },
    ],
    allOpenedCount: [
      { language: "cs", label: "Otevření celkem" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Komponent celkem" },
      { language: "en", label: "" },
    ],
    top: [
      { language: "cs", label: "Nejpouživanější komponenty" },
      { language: "en", label: "" },
    ],
    days: [
      { language: "cs", label: "Využití komponent" },
      { language: "en", label: "" },
    ],
  },
  statisticsUsersUsage: {
    title: [
      { language: "cs", label: "Statistika uživatelů" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Registrovaných uživatelů celkem" },
      { language: "en", label: "" },
    ],
    days: [
      { language: "cs", label: "Registrace uživatelů" },
      { language: "en", label: "" },
    ],
  },
  statisticsNotifications: {
    title: [
      { language: "cs", label: "Statistika notifikací" },
      { language: "en", label: "" },
    ],
    all: [
      { language: "cs", label: "Vše" },
      { language: "en", label: "" },
    ],
    count: [
      { language: "cs", label: "Posláno notifikací celkem" },
      { language: "en", label: "" },
    ],
    days: [
      { language: "cs", label: "Využití notifikací" },
      { language: "en", label: "" },
    ],
    users: [
      { language: "cs", label: "Počet uživatelů" },
      { language: "en", label: "" },
    ],
    opened: [
      { language: "cs", label: "Počet otevření" },
      { language: "en", label: "" },
    ],
    openedHistory: [
      { language: "cs", label: "Počet otevření z historie" },
      { language: "en", label: "" },
    ],
    date: [
      { language: "cs", label: "Datum" },
      { language: "en", label: "" },
    ],
  },
  path: {
    home: [
      { language: "cs", label: "Aplikace" },
      { language: "en", label: "" },
    ],
    appSections: [
      { language: "cs", label: "Sekce aplikace" },
      { language: "en", label: "" },
    ],
    notifications: [
      { language: "cs", label: "Notifikační zprávy" },
      { language: "en", label: "" },
    ],
    appManagement: [
      { language: "cs", label: "Práce s aplikací" },
      { language: "en", label: "" },
    ],
    chat: [
      { language: "cs", label: "Chat" },
      { language: "en", label: "" },
    ],
    care: [
      { language: "cs", label: "Zákaznický portál" },
      { language: "en", label: "" },
    ],
    screens: [
      { language: "cs", label: "Úprava aplikace" },
      { language: "en", label: "" },
    ],
    appSettings: [
      { language: "cs", label: "Nastavení aplikace" },
      { language: "en", label: "" },
    ],
    eshop: [
      { language: "cs", label: "Eshop" },
      { language: "en", label: "" },
    ],
    appExtras: [
      { language: "cs", label: "Doplňkové moduly" },
      { language: "en", label: "" },
    ],
    appUsers: [
      { language: "cs", label: "Uživatelé aplikací" },
      { language: "en", label: "" },
    ],
    appStatistics: [
      { language: "cs", label: "Statistika" },
      { language: "en", label: "" },
    ],
    notificationsPush: [
      { language: "cs", label: "Notifikační zprávy" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPush: [
      { language: "cs", label: "Geolokační notifikační zprávy" },
      { language: "en", label: "" },
    ],
    notificationsEmail: [
      { language: "cs", label: "E-maily" },
      { language: "en", label: "" },
    ],
    notificationsSms: [
      { language: "cs", label: "SMS" },
      { language: "en", label: "" },
    ],
    notificationsPushSend: [
      { language: "cs", label: "Odeslat notifikaci" },
      { language: "en", label: "" },
    ],
    notificationsPushHistory: [
      { language: "cs", label: "Historie notifikací" },
      { language: "en", label: "" },
    ],
    notificationsPushScheduled: [
      { language: "cs", label: "Naplánované notifikační zprávy" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushSend: [
      { language: "cs", label: "Odeslat geolokační notifikaci" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushHistory: [
      { language: "cs", label: "Historie geolokačních notifikací" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushScheduled: [
      { language: "cs", label: "Naplánované geolokační notifikační zprávy" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushZones: [
      { language: "cs", label: "Geolokační zóny" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushZone: [
      { language: "cs", label: "Geolokační zóna" },
      { language: "en", label: "" },
    ],
    notificationsGeolocationPushNewZone: [
      { language: "cs", label: "Nová geolokační zóna" },
      { language: "en", label: "" },
    ],
    notificationsEmailSend: [
      { language: "cs", label: "Odeslat e-mail" },
      { language: "en", label: "" },
    ],
    notificationsEmailHistory: [
      { language: "cs", label: "Historie e-mailů" },
      { language: "en", label: "" },
    ],
    notificationsEmailScheduled: [
      { language: "cs", label: "Naplánované emaily" },
      { language: "en", label: "" },
    ],
    notificationsSmsSend: [
      { language: "cs", label: "Odeslat SMS" },
      { language: "en", label: "" },
    ],
    notificationsSmsHistory: [
      { language: "cs", label: "Historie SMS" },
      { language: "en", label: "" },
    ],
    notificationsSmsScheduled: [
      { language: "cs", label: "Naplánované SMS zprávy" },
      { language: "en", label: "" },
    ],
    notificationsRegistered: [
      { language: "cs", label: "Notifikační zprávy pro přihlášené uživatele" },
      { language: "en", label: "" },
    ],
    notificationsRegisteredSend: [
      { language: "cs", label: "Poslat notifikaci" },
      { language: "en", label: "" },
    ],
    notificationsRegisteredStreets: [
      { language: "cs", label: "Ulice" },
      { language: "en", label: "" },
    ],
    notificationsRegisteredUsers: [
      { language: "cs", label: "Uživatelé" },
      { language: "en", label: "" },
    ],
    forms: [
      { language: "cs", label: "Správa formulářů" },
      { language: "en", label: "" },
    ],
    formResults: [
      { language: "cs", label: "Výsledky formuláře" },
      { language: "en", label: "" },
    ],
    formResult: [
      { language: "cs", label: "Výsledek formuláře" },
      { language: "en", label: "" },
    ],
    quizzes: [
      { language: "cs", label: "Správa kvízů" },
      { language: "en", label: "" },
    ],
    newQuiz: [
      { language: "cs", label: "Vytvoření nového kvízu" },
      { language: "en", label: "" },
    ],
    quiz: [
      { language: "cs", label: "Kvíz" },
      { language: "en", label: "" },
    ],
    quizResults: [
      { language: "cs", label: "Výsledky kvízu" },
      { language: "en", label: "" },
    ],
    quizResult: [
      { language: "cs", label: "Výsledky kvízu" },
      { language: "en", label: "" },
    ],
    leaflets: [
      { language: "cs", label: "Správa letáků" },
      { language: "en", label: "" },
    ],
    newLeaflet: [
      { language: "cs", label: "Vytvoření nového letáku" },
      { language: "en", label: "" },
    ],
    leaflet: [
      { language: "cs", label: "Správa letáku" },
      { language: "en", label: "" },
    ],
    maps: [
      { language: "cs", label: "Mapy" },
      { language: "en", label: "" },
    ],
    newMap: [
      { language: "cs", label: "Vytvoření nové mapy" },
      { language: "en", label: "" },
    ],
    map: [
      { language: "cs", label: "Mapa" },
      { language: "en", label: "" },
    ],
    mapEdit: [
      { language: "cs", label: "Mapa" },
      { language: "en", label: "" },
    ],
    chatRooms: [
      { language: "cs", label: "Chatovací místnosti" },
      { language: "en", label: "" },
    ],
    chatRoom: [
      { language: "cs", label: "Chatovací místnost" },
      { language: "en", label: "" },
    ],
    chatSettings: [
      { language: "cs", label: "Nastavení chatu" },
      { language: "en", label: "" },
    ],
    paros: [
      { language: "cs", label: "Participační rozpočty" },
      { language: "en", label: "" },
    ],
    newParo: [
      { language: "cs", label: "Vytvořit participační rozpočet" },
      { language: "en", label: "" },
    ],
    paro: [
      { language: "cs", label: "Participační rozpočet" },
      { language: "en", label: "" },
    ],
    newParoScreen: [
      { language: "cs", label: "Vytvořit stránku participačního rozpočtu" },
      { language: "en", label: "" },
    ],
    paroScreen: [
      { language: "cs", label: "Stránku participačního rozpočtu" },
      { language: "en", label: "" },
    ],
    accomodation: [
      { language: "cs", label: "Pro ubytovatele" },
      { language: "en", label: "" },
    ],
    owner: [
      { language: "cs", label: "Karta ubytovatele" },
      { language: "en", label: "" },
    ],
    newScreen: [
      { language: "cs", label: "Vytvoření nové stránky" },
      { language: "en", label: "" },
    ],
    screenSettings: [
      { language: "cs", label: "Stránka" },
      { language: "en", label: "" },
    ],
    screenBasicParameters: [
      { language: "cs", label: "Základní nastavení stránky" },
      { language: "en", label: "" },
    ],
    screenDrawer: [
      { language: "cs", label: "Postranní menu aplikace" },
      { language: "en", label: "" },
    ],
    screenTabs: [
      { language: "cs", label: "Dolní navigační lišta" },
      { language: "en", label: "" },
    ],
    screenBackgroundImage: [
      { language: "cs", label: "Obrázek pozadí" },
      { language: "en", label: "" },
    ],
    screenHeaderImage: [
      { language: "cs", label: "Obrázek hlavičky" },
      { language: "en", label: "" },
    ],
    screenEditor: [
      { language: "cs", label: "Editor stránky" },
      { language: "en", label: "" },
    ],
    appBasicParameters: [
      { language: "cs", label: "Základní nastavení" },
      { language: "en", label: "" },
    ],
    appStudioSettings: [
      { language: "cs", label: "Nastavení studia" },
      { language: "en", label: "" },
    ],
    appLanguages: [
      { language: "cs", label: "Jazykové mutace" },
      { language: "en", label: "" },
    ],
    appPermissions: [
      { language: "cs", label: "Práva aplikace" },
      { language: "en", label: "" },
    ],
    appIcon: [
      { language: "cs", label: "Ikona aplikace" },
      { language: "en", label: "" },
    ],
    appSplash: [
      { language: "cs", label: "Úvodní obrázek" },
      { language: "en", label: "" },
    ],
    appNotifications: [
      { language: "cs", label: "Nastavení notifikací" },
      { language: "en", label: "" },
    ],
    welcome: [
      { language: "cs", label: "Úvodní jednorázová informace" },
      { language: "en", label: "" },
    ],
    appSystemMessages: [
      { language: "cs", label: "Systémové hlášky" },
      { language: "en", label: "" },
    ],
    appWebview: [
      { language: "cs", label: "Vložený web" },
      { language: "en", label: "" },
    ],
    appLogin: [
      { language: "cs", label: "Přihlášení" },
      { language: "en", label: "" },
    ],
    appLikedMarker: [
      { language: "cs", label: "Oblíbené místo v mapě" },
      { language: "en", label: "" },
    ],
    coupons: [
      { language: "cs", label: "Lokace kupónů" },
      { language: "en", label: "" },
    ],
    smartCoupons: [
      { language: "cs", label: "Kupóny" },
      { language: "en", label: "" },
    ],
    placesCoupons: [
      { language: "cs", label: "Lokace kupónů" },
      { language: "en", label: "" },
    ],
    placeCoupons: [
      { language: "cs", label: "Editor kupónů" },
      { language: "en", label: "" },
    ],
    appUserNotifications: [
      { language: "cs", label: "Uživatelské notifikace" },
      { language: "en", label: "" },
    ],
    appSearch: [
      { language: "cs", label: "Vyhledávání v aplikaci" },
      { language: "en", label: "" },
    ],
    appCoupon: [
      { language: "cs", label: "Kupón" },
      { language: "en", label: "" },
    ],
    appPaid: [
      { language: "cs", label: "Placené sekce" },
      { language: "en", label: "" },
    ],
    appAdmin: [
      { language: "cs", label: "Administrátorská sekce" },
      { language: "en", label: "" },
    ],
    appOta: [
      { language: "cs", label: "OTA" },
      { language: "en", label: "" },
    ],
    mindfulnessList: [
      { language: "cs", label: "Základní dechová cvičení" },
      { language: "en", label: "" },
    ],
    newMindfulness: [
      { language: "cs", label: "Vytvoření nového cvičení" },
      { language: "en", label: "" },
    ],
    mindfulness: [
      { language: "cs", label: "Cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessProList: [
      { language: "cs", label: "Pokročilejší dechová cvičení" },
      { language: "en", label: "" },
    ],
    newMindfulnessPro: [
      { language: "cs", label: "Vytvoření nového cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessPro: [
      { language: "cs", label: "Cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessTouchList: [
      { language: "cs", label: "Dotykové dechová cvičení" },
      { language: "en", label: "" },
    ],
    newMindfulnessTouch: [
      { language: "cs", label: "Vytvoření nového cvičení" },
      { language: "en", label: "" },
    ],
    mindfulnessTouch: [
      { language: "cs", label: "Cvičení" },
      { language: "en", label: "" },
    ],
    appNewUser: [
      { language: "cs", label: "Vytvoření nového uživatele" },
      { language: "en", label: "" },
    ],
    appUser: [
      { language: "cs", label: "Uživatel aplikace" },
      { language: "en", label: "" },
    ],
    statisticsSms: [
      { language: "cs", label: "Statistika odeslaných SMS" },
      { language: "en", label: "" },
    ],
    statisticsEmail: [
      { language: "cs", label: "Statistika odeslaných E-mailů" },
      { language: "en", label: "" },
    ],
    statisticsAppDownload: [
      { language: "cs", label: "Statistika stažení aplikace" },
      { language: "en", label: "" },
    ],
    statisticsAppUsage: [
      { language: "cs", label: "Statistika otevření aplikace" },
      { language: "en", label: "" },
    ],
    statisticsScreensUsage: [
      { language: "cs", label: "Statistika stránek" },
      { language: "en", label: "" },
    ],
    statisticsComponentsUsage: [
      { language: "cs", label: "Statistika komponent" },
      { language: "en", label: "" },
    ],
    statisticsUsers: [
      { language: "cs", label: "Statistika uživatelů" },
      { language: "en", label: "" },
    ],
    statisticsNotifications: [
      { language: "cs", label: "Statistika notifikací" },
      { language: "en", label: "" },
    ],
    appUserList: [
      { language: "cs", label: "Přehled uživatelských účtů" },
      { language: "en", label: "" },
    ],
    users: [
      { language: "cs", label: "Uživatelé studia" },
      { language: "en", label: "" },
    ],
    user: [
      { language: "cs", label: "Uživatel" },
      { language: "en", label: "" },
    ],
    newUser: [
      { language: "cs", label: "Přidat nového uživatele" },
      { language: "en", label: "" },
    ],
    subjects: [
      { language: "cs", label: "Subjekty" },
      { language: "en", label: "" },
    ],
    newApp: [
      { language: "cs", label: "Vytvoření nové aplikace" },
      { language: "en", label: "" },
    ],
    templates: [
      { language: "cs", label: "Šablony a ukázky" },
      { language: "en", label: "" },
    ],
    coupon: [
      { language: "cs", label: "Přidat správu kupónů" },
      { language: "en", label: "" },
    ],
    couponAdmin: [
      { language: "cs", label: "Správa kupónů" },
      { language: "en", label: "" },
    ],
    couponList: [
      { language: "cs", label: "Seznam kupónů" },
      { language: "en", label: "" },
    ],
    newCoupon: [
      { language: "cs", label: "Vytvoření nové správy kupónů" },
      { language: "en", label: "" },
    ],
    couponEditor: [
      { language: "cs", label: "Editor kupónů" },
      { language: "en", label: "" },
    ],
    videoScreenEditor: [
      { language: "cs", label: "Editor stránky s videem" },
      { language: "en", label: "" },
    ],
  },

  care: {
    title: [
      { language: "cs", label: "Zákaznický portál" },
      { language: "en", label: "" },
    ],
    logoText: [
      { language: "cs", label: "Novinky ve Vaší aplikaci" },
      { language: "en", label: "" },
    ],
    statusText: [
      { language: "cs", label: "Aktuální stav aplikace:" },
      { language: "en", label: "" },
    ],
    statusPlaceholder: [
      { language: "cs", label: "Aktuální stav aplikace" },
      { language: "en", label: "" },
    ],
    saveStatus: [
      { language: "cs", label: "Uložit stav aplikace" },
      { language: "en", label: "" },
    ],
    images: [
      { language: "cs", label: "Grafické podklady" },
      { language: "en", label: "" },
    ],
    imageTextPlaceholder: [
      { language: "cs", label: "Název obrázku" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "Zde nahrajte obrázek." },
      { language: "en", label: "" },
    ],
    saveImage: [
      { language: "cs", label: "Uložit obrázek" },
      { language: "en", label: "" },
    ],
    documents: [
      { language: "cs", label: "Dokumenty" },
      { language: "en", label: "" },
    ],
    documentTextPlaceholder: [
      { language: "cs", label: "Název dokumentu" },
      { language: "en", label: "" },
    ],
    documentPlaceholder: [
      { language: "cs", label: "Zde nahrajte dokument." },
      { language: "en", label: "" },
    ],
    saveDocument: [
      { language: "cs", label: "Uložit dokument" },
      { language: "en", label: "" },
    ],
    videos: [
      { language: "cs", label: "Videonávody" },
      { language: "en", label: "" },
    ],
    videoTextPlaceholder: [
      { language: "cs", label: "Název videa" },
      { language: "en", label: "" },
    ],
    videoPlaceholder: [
      { language: "cs", label: "Zde nahrajte video." },
      { language: "en", label: "" },
    ],
    saveVideo: [
      { language: "cs", label: "Uložit video" },
      { language: "en", label: "" },
    ],
    newMessage: [
      { language: "cs", label: "Zde napište novou zprávu" },
      { language: "en", label: "" },
    ],
    messageTitlePlaceholder: [
      { language: "cs", label: "Zde napište nadpis zprávy" },
      { language: "en", label: "" },
    ],
    messageTextPlaceholder: [
      { language: "cs", label: "Zde napište text zprávy" },
      { language: "en", label: "" },
    ],
    saveMessage: [
      { language: "cs", label: "Uložit zprávu" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Odeslat oznámení" },
      { language: "en", label: "" },
    ],
    sendCustomerEmail: [
      { language: "cs", label: "Napište nám" },
      { language: "en", label: "" },
    ],
    customerEmailModalTitle: [
      {
        language: "cs",
        label:
          "Váše zpráva bude odeslana na email mobilniaplikace@appsisto.com",
      },
      { language: "en", label: "" },
    ],
    customerEmailText: [
      { language: "cs", label: "Zde napište text emailu" },
      { language: "en", label: "" },
    ],
    closeCustomerEmailModal: [
      { language: "cs", label: "Zavřít" },
      { language: "en", label: "" },
    ],
    add: [
      { language: "cs", label: "Přidat" },
      { language: "en", label: "" },
    ],
    careMediaLinkPlaceholder: [
      { language: "cs", label: "Přidat grafické podklady" },
      { language: "en", label: "" },
    ],
    careMediaDocumentPlaceholder: [
      { language: "cs", label: "Přidat dokument" },
      { language: "en", label: "" },
    ],
    namePlaceholder: [
      { language: "cs", label: "Název souboru" },
      { language: "en", label: "" },
    ],
  },
  newGeolocationZone: {
    title: [
      { language: "cs", label: "Vytvoření nové geolokaċní zóny" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    required: [
      { language: "cs", label: "Je vyžadován název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit novou geolokaċní zónu" },
      { language: "en", label: "" },
    ],
  },
  geolocationZone: {
    zone: [
      { language: "cs", label: "Zóna" },
      { language: "en", label: "" },
    ],
    title: [
      { language: "cs", label: "Nadpis" },
      { language: "en", label: "" },
    ],
    titlePlaceholder: [
      {
        language: "cs",
        label:
          "Uveďte nadpis notifikační zprávy. Doporučujeme maximálně 40 znaků.",
      },
      { language: "en", label: "" },
    ],
    text: [
      { language: "cs", label: "Zpráva" },
      { language: "en", label: "" },
    ],
    textPlaceholder: [
      {
        language: "cs",
        label: "Napište svou zprávu.",
      },
      { language: "en", label: "" },
    ],
    timeInterval: [
      {
        language: "cs",
        label: "Časový interval",
      },
      { language: "en", label: "" },
    ],
    from: [
      {
        language: "cs",
        label: "Od",
      },
      { language: "en", label: "" },
    ],
    to: [
      {
        language: "cs",
        label: "Do",
      },
      { language: "en", label: "" },
    ],
    action: [
      {
        language: "cs",
        label: "Akce zprávy",
      },
      { language: "en", label: "" },
    ],
    actionPlaceholder: [
      {
        language: "cs",
        label: "Vyberte následnou akci z notifikační zprávy.",
      },
      { language: "en", label: "" },
    ],
    dropZonePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte pdf soubor.",
      },
      { language: "en", label: "" },
    ],
    noAction: [
      { language: "cs", label: "Bez akce" },
      { language: "en", label: "" },
    ],
    navigationAction: [
      { language: "cs", label: "Přesunout na jinou stránku" },
      { language: "en", label: "" },
    ],
    urlAction: [
      { language: "cs", label: "Otevřít webovou stránku mimo aplikaci" },
      { language: "en", label: "" },
    ],
    webviewAction: [
      { language: "cs", label: "Otevřít webovou stránku uvnitř aplikace" },
      { language: "en", label: "" },
    ],
    pdfAction: [
      { language: "cs", label: "Zobrazit dokument PDF" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte obrázek.",
      },
      { language: "en", label: "" },
    ],
    images: [
      {
        language: "cs",
        label: "Obrázky",
      },
      { language: "en", label: "" },
    ],
    imagesPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte obrázky.",
      },
      { language: "en", label: "" },
    ],
    audio: [
      {
        language: "cs",
        label: "Audio",
      },
      { language: "en", label: "" },
    ],
    audioPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte audio.",
      },
      { language: "en", label: "" },
    ],
    audioPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte audio.",
      },
      { language: "en", label: "" },
    ],
    video: [
      {
        language: "cs",
        label: "Video",
      },
      { language: "en", label: "" },
    ],
    videoPlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte video.",
      },
      { language: "en", label: "" },
    ],

    uploadedImages: [
      {
        language: "cs",
        label: "Nahrané obrázky",
      },
      { language: "en", label: "" },
    ],
    uploadedAudios: [
      {
        language: "cs",
        label: "Nahrané audio",
      },
      { language: "en", label: "" },
    ],
    uploadedVideos: [
      {
        language: "cs",
        label: "Nahrané videa",
      },
      { language: "en", label: "" },
    ],
    imagesSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání obrázků...",
      },
      { language: "en", label: "" },
    ],
    audioSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání audio souborů...",
      },
      { language: "en", label: "" },
    ],
    videoSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání video souborů...",
      },
      { language: "en", label: "" },
    ],
    pushSending: [
      {
        language: "cs",
        label: "Probíhá odesílání notifikace...",
      },
      { language: "en", label: "" },
    ],
    confirmText: [
      { language: "cs", label: "Odeslání zprávy proběhlo úspěšně." },
      { language: "en", label: "" },
    ],
    errorText: [
      {
        language: "cs",
        label: "Odeslání zprávy selhalo. Pošlete zprávu znovu.",
      },
      { language: "en", label: "" },
    ],
    confirmSendNext: [
      { language: "cs", label: "Zavřít" },
      { language: "en", label: "" },
    ],
    confirmHome: [
      { language: "cs", label: "Domů" },
      { language: "en", label: "" },
    ],
    update: [
      { language: "cs", label: "Aktualizovat" },
      { language: "en", label: "" },
    ],
  },
  usersDashboard: {
    title: [
      { language: "cs", label: "Nastavte si uživatelské účty" },
      { language: "en", label: "" },
    ],
    appUsers: [
      { language: "cs", label: "Uživatelé aplikace" },
      { language: "en", label: "" },
    ],
    studioUsers: [
      { language: "cs", label: "Uživatelé studia" },
      { language: "en", label: "" },
    ],
  },

  mainDashboard: {
    logout: [
      { language: "cs", label: "Odhlásit" },
      { language: "en", label: "Logout" },
    ],
    home: [
      { language: "cs", label: "Domů" },
      { language: "en", label: "Home" },
    ],
    notifications: [
      { language: "cs", label: "Oznámení" },
      { language: "en", label: "Notifications" },
    ],
    appEdit: [
      { language: "cs", label: "Úprava aplikace" },
      { language: "en", label: "App Editting" },
    ],
    modules: [
      { language: "cs", label: "Moduly" },
      { language: "en", label: "Modules" },
    ],
    accounts: [
      { language: "cs", label: "Účty" },
      { language: "en", label: "Accounts" },
    ],
    tips: [
      { language: "cs", label: "Tipy" },
      { language: "en", label: "Tips" },
    ],
    statisitcs: [
      { language: "cs", label: "Statistika" },
      { language: "en", label: "Statisitcs" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "Settings" },
    ],
    extras: [
      { language: "cs", label: "Doplňkové moduly" },
      { language: "en", label: "Extras" },
    ],
    dashboard: [
      { language: "cs", label: "Dashboard" },
      { language: "en", label: "Dashboard" },
    ],
    subjects: [
      { language: "cs", label: "Subjekty" },
      { language: "en", label: "Subjects" },
    ],
  },
  smartNotificationsPush: {
    titlePush: [
      { language: "cs", label: "Odeslat notifikaci" },
      { language: "en", label: "" },
    ],
    titleEmail: [
      { language: "cs", label: "Odeslat e-mail" },
      { language: "en", label: "" },
    ],
    titleSms: [
      { language: "cs", label: "Odeslat Sms" },
      { language: "en", label: "" },
    ],
    textTitle: [
      { language: "cs", label: "Text oznámení" },
      { language: "en", label: "" },
    ],
    phoneTitle: [
      { language: "cs", label: "Náhled oznámení" },
      { language: "en", label: "" },
    ],
    attachmentsTitle: [
      { language: "cs", label: "Přílohy" },
      { language: "en", label: "" },
    ],
    settingsTitle: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    buttonSettingsTitle: [
      { language: "cs", label: "Tlačítko" },
      { language: "en", label: "" },
    ],
    sendOptions: [
      { language: "cs", label: "Nastavení odeslání" },
      { language: "en", label: "" },
    ],
    useSms: [
      { language: "cs", label: "Použít SMS" },
      { language: "en", label: "" },
    ],
    useEmail: [
      { language: "cs", label: "Použít Email" },
      { language: "en", label: "" },
    ],
    usePush: [
      { language: "cs", label: "Použít Push notifikace" },
      { language: "en", label: "" },
    ],
    notificationTitle: [
      { language: "cs", label: "Nadpis" },
      { language: "en", label: "" },
    ],
    notificationText: [
      { language: "cs", label: "Text" },
      { language: "en", label: "" },
    ],
    notificationTitlePlaceholder: [
      {
        language: "cs",
        label:
          "Uveďte nadpis notifikační zprávy. Doporučujeme maximálně 40 znaků",
      },
      { language: "en", label: "" },
    ],
    notificationEmailTitlePlaceholder: [
      {
        language: "cs",
        label: "Uveďte předmět e-mailu",
      },
      { language: "en", label: "" },
    ],
    notificationSmsTitlePlaceholder: [
      {
        language: "cs",
        label: "Uveďte nadpis SMS",
      },
      { language: "en", label: "" },
    ],
    notificationTextPlaceholder: [
      {
        language: "cs",
        label: "Napíšte svou zprávu",
      },
      { language: "en", label: "" },
    ],
    imageButtonTitle: [
      { language: "cs", label: "Přidat obrázek" },
      { language: "en", label: "" },
    ],
    fileButtonTitle: [
      { language: "cs", label: "Přidat PDF" },
      { language: "en", label: "" },
    ],
    videoButtonTitle: [
      { language: "cs", label: "Přidat video" },
      { language: "en", label: "" },
    ],
    audioButtonTitle: [
      { language: "cs", label: "Přidat audio" },
      { language: "en", label: "" },
    ],
    sendImmediately: [
      { language: "cs", label: "Odeslat ihned" },
      { language: "en", label: "" },
    ],
    sendTermin: [
      { language: "cs", label: "Naplánovat termín" },
      { language: "en", label: "" },
    ],
    notificationSegmentsTitle: [
      { language: "cs", label: "Vybrat skupinu" },
      { language: "en", label: "" },
    ],
    notificationSegmentAll: [
      { language: "cs", label: "Všem" },
      { language: "en", label: "" },
    ],
    buttonSettingsChoice: [
      { language: "cs", label: "Zobrazit tlačítko" },
      { language: "en", label: "" },
    ],
    notificationActionPlaceholder: [
      {
        language: "cs",
        label: "Vyberte následnou akci z notifikační zprávy.",
      },
      { language: "en", label: "" },
    ],
    navigationAction: [
      { language: "cs", label: "Přesunout na jinou stránku" },
      { language: "en", label: "" },
    ],
    urlAction: [
      { language: "cs", label: "Otevřít webovou stránku mimo aplikaci" },
      { language: "en", label: "" },
    ],
    webviewAction: [
      { language: "cs", label: "Otevřít webovou stránku uvnitř aplikace" },
      { language: "en", label: "" },
    ],
    phoneAction: [
      { language: "cs", label: "Vytočit telefonní číslo" },
      { language: "en", label: "" },
    ],
    noAction: [
      { language: "cs", label: "Bez akce" },
      { language: "en", label: "" },
    ],
    imageModal: [
      {
        language: "cs",
        label: "Zde nahrajte obrázek",
      },
      { language: "en", label: "" },
    ],
    pdfModal: [
      {
        language: "cs",
        label: "Zde nahrajte pdf soubor",
      },
      { language: "en", label: "" },
    ],
    audioModal: [
      {
        language: "cs",
        label: "Zde nahrajte audio",
      },
      { language: "en", label: "" },
    ],
    videoModal: [
      {
        language: "cs",
        label: "Zde nahrajte video",
      },
      { language: "en", label: "" },
    ],
    filePlaceholder: [
      {
        language: "cs",
        label: "Zde nahrajte soubor",
      },
      { language: "en", label: "" },
    ],
    closeMessagesModal: [
      { language: "cs", label: "Zavřit" },
      { language: "en", label: "" },
    ],
    buttonTitle: [
      { language: "cs", label: "Text tlačítka" },
      { language: "en", label: "" },
    ],
    notificationAction: [
      {
        language: "cs",
        label: "Akce tlačítka",
      },
      { language: "en", label: "" },
    ],
    send: [
      { language: "cs", label: "Odeslat notifikaci" },
      { language: "en", label: "" },
    ],
    sendEmail: [
      { language: "cs", label: "Odeslat Email" },
      { language: "en", label: "" },
    ],
    sendSms: [
      { language: "cs", label: "Odeslat SMS" },
      { language: "en", label: "" },
    ],
    imagesSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání obrázků...",
      },
      { language: "en", label: "" },
    ],
    audioSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání audio souborů...",
      },
      { language: "en", label: "" },
    ],
    videoSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání video souborů...",
      },
      { language: "en", label: "" },
    ],
    pdfSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání PDF souborů...",
      },
      { language: "en", label: "" },
    ],
    pushSending: [
      {
        language: "cs",
        label: "Probíhá odesílání notifikace...",
      },
      { language: "en", label: "" },
    ],
    confirmText: [
      { language: "cs", label: "Odeslání zprávy proběhlo úspěšně." },
      { language: "en", label: "" },
    ],
    errorText: [
      {
        language: "cs",
        label: "Odeslání zprávy selhalo. Pošlete zprávu znovu.",
      },
      { language: "en", label: "" },
    ],
    confirmSendNext: [
      { language: "cs", label: "Zavřít" },
      { language: "en", label: "" },
    ],
    confirmHome: [
      { language: "cs", label: "Domů" },
      { language: "en", label: "" },
    ],
    confirmSendAgain: [
      { language: "cs", label: "Poslat znovu" },
      { language: "en", label: "" },
    ],
    testEmail: [
      { language: "cs", label: "Testovací e-mail" },
      { language: "en", label: "" },
    ],
    testEmailChoice: [
      { language: "cs", label: "Zobrazit testovací e-mail" },
      { language: "en", label: "" },
    ],
    testSms: [
      { language: "cs", label: "Testovací SMS" },
      { language: "en", label: "" },
    ],
    emailAddress: [
      { language: "cs", label: "E-mailová adresa" },
      { language: "en", label: "" },
    ],
    phoneNumber: [
      { language: "cs", label: "Telefonní číslo" },
      { language: "en", label: "" },
    ],
    emailAddressPlaceholder: [
      {
        language: "cs",
        label: "Zadejte adresu, na kterou obdržíte testovací e-mail.",
      },
      { language: "en", label: "" },
    ],
    phoneNumberPlaceholder: [
      {
        language: "cs",
        label:
          "Zadejte telefonní číslo, na které obdržíte testovací SMS zprávu.",
      },
      { language: "en", label: "" },
    ],
    isTest: [
      { language: "cs", label: "Poslat testovací zprávu" },
      { language: "en", label: "" },
    ],
    emailSubject: [
      { language: "cs", label: "Předmět e-mailu" },
      { language: "en", label: "" },
    ],
    confirmDialogTitle: [
      { language: "cs", label: "Opravdu si přejete notifikaci odeslat?" },
      { language: "en", label: "" },
    ],
    confirmDialogText: [
      { language: "cs", label: "Notifikace bude odeslaná!!!" },
      { language: "en", label: "" },
    ],
    confirmDialogBack: [
      { language: "cs", label: "Zpět" },
      { language: "en", label: "" },
    ],
    confirmDialogSend: [
      { language: "cs", label: "Odeslat Notifikaci" },
      { language: "en", label: "" },
    ],
  },
  smartScreens: {
    title: [
      { language: "cs", label: "Úprava aplikace" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Vyhledejte stránku" },
      { language: "en", label: "" },
    ],
    filter: [
      { language: "cs", label: "Filtr" },
      { language: "en", label: "" },
    ],
    newScreen: [
      { language: "cs", label: "Přidat novou stránku" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    delete: [
      { language: "cs", label: "Smazat" },
      { language: "en", label: "" },
    ],
    screenContent: [
      { language: "cs", label: "Obsah stránky" },
      { language: "en", label: "" },
    ],
    backgroundImage: [
      { language: "cs", label: "Pozadí stránky" },
      { language: "en", label: "" },
    ],
  },

  paros: {
    title: [
      { language: "cs", label: "Participační rozpočty" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    newParo: [
      { language: "cs", label: "Vytvořit nový participační rozpočet" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    copy: [
      { language: "cs", label: "Kopírovat odkaz na participační rozpočet" },
      { language: "en", label: "" },
    ],
    results: [
      { language: "cs", label: "Výsledky participačního rozpočtu" },
      { language: "en", label: "" },
    ],
  },

  newParo: {
    title: [
      { language: "cs", label: "Vytvoření nového participačního rozpočtu" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit" },
      { language: "en", label: "" },
    ],
  },
  paro: {
    title: [
      { language: "cs", label: "Participační rozpočet" },
      { language: "en", label: "" },
    ],
    basicParameters: [
      { language: "cs", label: "Základní parametry" },
      { language: "en", label: "" },
    ],
    useVerification: [
      { language: "cs", label: "Použít verifikaci" },
      { language: "en", label: "" },
    ],
    verificationType: [
      { language: "cs", label: "Typ verifikace" },
      { language: "en", label: "" },
    ],
    verificationSms: [
      { language: "cs", label: "Sms" },
      { language: "en", label: "" },
    ],
    verificationEmail: [
      { language: "cs", label: "E-mail" },
      { language: "en", label: "" },
    ],
    labelWaiting: [
      { language: "cs", label: "Text před zahájením" },
      { language: "en", label: "" },
    ],
    labelClosed: [
      { language: "cs", label: "Text po ukončení" },
      { language: "en", label: "" },
    ],
    labelDemo: [
      { language: "cs", label: "Text pro demo" },
      { language: "en", label: "" },
    ],
    labelResults: [
      { language: "cs", label: "Text pro zobrazení výsledků" },
      { language: "en", label: "" },
    ],
    labelSendTitle: [
      { language: "cs", label: "Nadpis před odesláním výsledků" },
      { language: "en", label: "" },
    ],
    labelSendText: [
      { language: "cs", label: "Text před odesláním výsledků" },
      { language: "en", label: "" },
    ],
    labelVerifySms: [
      { language: "cs", label: "Ověření pomocí SMS" },
      { language: "en", label: "" },
    ],
    labelVerifyEmail: [
      { language: "cs", label: "Ověření pomocí E-mailu" },
      { language: "en", label: "" },
    ],
    labelSend: [
      { language: "cs", label: "Popisek tlačítka odeslat" },
      { language: "en", label: "" },
    ],
    labelPhone: [
      { language: "cs", label: "Popisek zadaní telefonního čísla" },
      { language: "en", label: "" },
    ],
    labelEmail: [
      { language: "cs", label: "Popisek zadaní e-mailu" },
      { language: "en", label: "" },
    ],
    labelCode: [
      { language: "cs", label: "Text ověřovacího kódu" },
      { language: "en", label: "" },
    ],
    labelFormError: [
      { language: "cs", label: "Povinné údaje" },
      { language: "en", label: "" },
    ],
    labelVoted: [
      { language: "cs", label: "Text, ze uz hlasoval" },
      { language: "en", label: "" },
    ],
    labelShowAnswers: [
      { language: "cs", label: "Text procházet odpovědi" },
      { language: "en", label: "" },
    ],
    labelFinalResult: [
      { language: "cs", label: "Text pro výsledek hlasování" },
      { language: "en", label: "" },
    ],
    labelPdfResult: [
      { language: "cs", label: "Text pro pdf" },
      { language: "en", label: "" },
    ],
    labelUrlResult: [
      { language: "cs", label: "Text pro url" },
      { language: "en", label: "" },
    ],
    pdfResult: [
      { language: "cs", label: "Zde nahrajte pdf s výsledky hlasování" },
      { language: "en", label: "" },
    ],
    urlResult: [
      { language: "cs", label: "Zde vložte url s výsledky hlasování" },
      { language: "en", label: "" },
    ],
    documentPlaceholder: [
      { language: "cs", label: "Zde nahrajte PDF dokument" },
      { language: "en", label: "" },
    ],
    activeFrom: [
      { language: "cs", label: "Datum zahájení" },
      { language: "en", label: "" },
    ],
    activeTo: [
      { language: "cs", label: "Datum ukončení" },
      { language: "en", label: "" },
    ],
    appearance: [
      { language: "cs", label: "Nastavení vzhledu" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "Obrázek" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "Zde nahrajte obrázek" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    paroScreens: [
      { language: "cs", label: "Stránky" },
      { language: "en", label: "" },
    ],
    download: [
      { language: "cs", label: "Uložit výsledky formuláře" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    backgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    backColor: [
      { language: "cs", label: "Barva šipky zpět" },
      { language: "en", label: "" },
    ],
    titleColor: [
      { language: "cs", label: "Barva nadpisu" },
      { language: "en", label: "" },
    ],
    descriptionColor: [
      { language: "cs", label: "Barva popisku" },
      { language: "en", label: "" },
    ],
    dateColor: [
      { language: "cs", label: "Barva termínu" },
      { language: "en", label: "" },
    ],
  },
  newParoScreen: {
    title: [
      {
        language: "cs",
        label: "Vytvoření nové stránky participačního rozpočtu",
      },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Vytvořit" },
      { language: "en", label: "" },
    ],
  },
  paroScreen: {
    title: [
      { language: "cs", label: "Nastavení stránky participačního rozpočtu" },
      { language: "en", label: "" },
    ],
    parameters: [
      { language: "cs", label: "Nastavení základních parametrů" },
      { language: "en", label: "" },
    ],
    appearance: [
      { language: "cs", label: "Nastavení vzhledu" },
      { language: "en", label: "" },
    ],
    image: [
      { language: "cs", label: "Obrázek" },
      { language: "en", label: "" },
    ],
    imagePlaceholder: [
      { language: "cs", label: "Zde nahrajte obrázek" },
      { language: "en", label: "" },
    ],
    editor: [
      { language: "cs", label: "Nastavení stránky" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název" },
      { language: "en", label: "" },
    ],
    description: [
      { language: "cs", label: "Popisek" },
      { language: "en", label: "" },
    ],
    allowDarkTheme: [
      { language: "cs", label: "Povolit tmavé téma" },
      { language: "en", label: "" },
    ],
    lightTheme: [
      { language: "cs", label: "Světlé téma" },
      { language: "en", label: "" },
    ],
    darkTheme: [
      { language: "cs", label: "Tmavé téma" },
      { language: "en", label: "" },
    ],
    backgroundColor: [
      { language: "cs", label: "Barva pozadí" },
      { language: "en", label: "" },
    ],
    textColor: [
      { language: "cs", label: "Barva textu" },
      { language: "en", label: "" },
    ],
    backColor: [
      { language: "cs", label: "Barva šipky zpět" },
      { language: "en", label: "" },
    ],
    titleColor: [
      { language: "cs", label: "Barva nadpisu" },
      { language: "en", label: "" },
    ],
    descriptionColor: [
      { language: "cs", label: "Barva popisku" },
      { language: "en", label: "" },
    ],
    questionColor: [
      { language: "cs", label: "Barva otázky" },
      { language: "en", label: "" },
    ],
    answerBackgroundColor: [
      { language: "cs", label: "Barva pozadí odpovědi" },
      { language: "en", label: "" },
    ],
    answerColor: [
      { language: "cs", label: "Barva textu odpovědi" },
      { language: "en", label: "" },
    ],
    selectedAnswerBackgroundColor: [
      { language: "cs", label: "Barva pozadí vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    selectedAnswerColor: [
      { language: "cs", label: "Barva textu vybrané odpovědi" },
      { language: "en", label: "" },
    ],
    pagesColor: [
      { language: "cs", label: "Barva čísla stránky" },
      { language: "en", label: "" },
    ],
    prevBackgroundColor: [
      { language: "cs", label: "Barva pozadí tlačítka předchozí" },
      { language: "en", label: "" },
    ],
    prevColor: [
      { language: "cs", label: "Barva textu tlačítka předchozí" },
      { language: "en", label: "" },
    ],
    nextBackgroundColor: [
      { language: "cs", label: "Barva pozadí tlačítka další" },
      { language: "en", label: "" },
    ],
    nextColor: [
      { language: "cs", label: "Barva textu tlačítka další" },
      { language: "en", label: "" },
    ],
    circleColor: [
      { language: "cs", label: "Barva kruhu" },
      { language: "en", label: "" },
    ],
    activeCircleColor: [
      { language: "cs", label: "Barva aktivního kruhu" },
      { language: "en", label: "" },
    ],
    answerGraphColor1: [
      { language: "cs", label: "Barva grafu 1" },
      { language: "en", label: "" },
    ],
    answerGraphColor2: [
      { language: "cs", label: "Barva grafu 2" },
      { language: "en", label: "" },
    ],
    answerGraphColor3: [
      { language: "cs", label: "Barva grafu 3" },
      { language: "en", label: "" },
    ],
    answerRadiusColor: [
      { language: "cs", label: "Rádius odpovědi" },
      { language: "en", label: "" },
    ],
    prevNextRadiusColor: [
      { language: "cs", label: "Rádius tlačítek" },
      { language: "en", label: "" },
    ],
    answerBorderRadius: [
      { language: "cs", label: "Zaoblení otázky" },
      { language: "en", label: "" },
    ],
    componentBorderRadius: [
      { language: "cs", label: "Zaoblení komponenty" },
      { language: "en", label: "" },
    ],
    prevNextBorderRadius: [
      { language: "cs", label: "Zaoblení navigačních tlačítek" },
      { language: "en", label: "" },
    ],
    formDescriptionColor: [
      { language: "cs", label: "Barva popisku formuláře" },
      { language: "en", label: "" },
    ],
    componentTitleColor: [
      { language: "cs", label: "Barva nadpisu komponenty" },
      { language: "en", label: "" },
    ],
    componentTextColor: [
      { language: "cs", label: "Barva textu komponenty" },
      { language: "en", label: "" },
    ],
    componentBackgroundColor: [
      { language: "cs", label: "Barva pozadí komponenty" },
      { language: "en", label: "" },
    ],
    required: [
      { language: "cs", label: "Povinné" },
      { language: "en", label: "" },
    ],
    question: [
      { language: "cs", label: "Otázka" },
      { language: "en", label: "" },
    ],
    answer: [
      { language: "cs", label: "Odpověď" },
      { language: "en", label: "" },
    ],
    form: [
      { language: "cs", label: "Popisek formuláře" },
      { language: "en", label: "" },
    ],
    formComponent: [
      { language: "cs", label: "Popisek komponenty formuláře" },
      { language: "en", label: "" },
    ],
    formComponentPlaceholder: [
      { language: "cs", label: "Nápověda komponenty formuláře" },
      { language: "en", label: "" },
    ],
    inputText: [
      { language: "cs", label: "Textový vstup" },
      { language: "en", label: "" },
    ],
    inputNumber: [
      { language: "cs", label: "Číselný vstup" },
      { language: "en", label: "" },
    ],
    inputEmail: [
      { language: "cs", label: "Email" },
      { language: "en", label: "" },
    ],
    selectSingle: [
      { language: "cs", label: "Výběr možnosti" },
      { language: "en", label: "" },
    ],
    selectMultiple: [
      { language: "cs", label: "Výběr z možností" },
      { language: "en", label: "" },
    ],
    inputLocation: [
      { language: "cs", label: "Lokace" },
      { language: "en", label: "" },
    ],
    inputCheckbox: [
      { language: "cs", label: "Ano/Ne" },
      { language: "en", label: "" },
    ],
    inputTrackbar: [
      { language: "cs", label: "Posuvník" },
      { language: "en", label: "" },
    ],
    inputTime: [
      { language: "cs", label: "Výběr času" },
      { language: "en", label: "" },
    ],
    inputDate: [
      { language: "cs", label: "Výběr datumu" },
      { language: "en", label: "" },
    ],
    inputDatetime: [
      { language: "cs", label: "Výběr datumu a času" },
      { language: "en", label: "" },
    ],
    inputImage: [
      { language: "cs", label: "Nahrání obrázku" },
      { language: "en", label: "" },
    ],
    inputPdf: [
      { language: "cs", label: "Nahrání PDF dokumentu" },
      { language: "en", label: "" },
    ],
    minValue: [
      { language: "cs", label: "Minimální hodnota" },
      { language: "en", label: "" },
    ],
    maxValue: [
      { language: "cs", label: "Maximální hodnota" },
      { language: "en", label: "" },
    ],
    defaultValue: [
      { language: "cs", label: "Výchozí hodnota" },
      { language: "en", label: "" },
    ],
    step: [
      { language: "cs", label: "Krok" },
      { language: "en", label: "" },
    ],
    options: [
      { language: "cs", label: "Možnosti" },
      { language: "en", label: "" },
    ],
    save: [
      { language: "cs", label: "Uložit" },
      { language: "en", label: "" },
    ],
  },

  smartMaps: {
    title: [
      { language: "cs", label: "Úprava aplikace" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Vyhledejte mapu" },
      { language: "en", label: "" },
    ],
    couponSearch: [
      { language: "cs", label: "Vyhledejte správu kupónů" },
      { language: "en", label: "" },
    ],
    filter: [
      { language: "cs", label: "Filtr" },
      { language: "en", label: "" },
    ],
    newMap: [
      { language: "cs", label: "Přidat novou mapu" },
      { language: "en", label: "" },
    ],
    newCoupon: [
      { language: "cs", label: "Přidat správu kupónů" },
      { language: "en", label: "" },
    ],
    index: [
      { language: "cs", label: "Index" },
      { language: "en", label: "" },
    ],
    settings: [
      { language: "cs", label: "Nastavení" },
      { language: "en", label: "" },
    ],
    mapContent: [
      { language: "cs", label: "Spravovat podněty" },
      { language: "en", label: "" },
    ],
    export: [
      { language: "cs", label: "Export mapy" },
      { language: "en", label: "" },
    ],
    import: [
      { language: "cs", label: "Import mapy" },
      { language: "en", label: "" },
    ],
    coupon: [
      { language: "cs", label: "Správa kupónů" },
      { language: "en", label: "" },
    ],

    dropText: [
      {
        language: "cs",
        label: "Zde přetáhněte tabulku s mapovými záznamy ve formátu .csv.",
      },
      { language: "en", label: "" },
    ],
    closeDropzone: [
      {
        language: "cs",
        label: "Zavřít",
      },
      { language: "en", label: "" },
    ],
    changeMapCoupon: [
      {
        language: "cs",
        label: "Změnit mapu na kupónovou",
      },
      { language: "en", label: "" },
    ],
    changeMapNormal: [
      {
        language: "cs",
        label: "Změnit mapu na obyčejnou",
      },
      { language: "en", label: "" },
    ],
  },
  smartHeader: {
    infoBox: [
      { language: "cs", label: "Novinky Studia" },
      { language: "en", label: "" },
    ],
    showInfo: [
      { language: "cs", label: "Zobrazit oznámení" },
      { language: "en", label: "" },
    ],
  },
  templates: {
    title: [
      { language: "cs", label: "Šablony a ukázky" },
      { language: "en", label: "" },
    ],
    altTitle: [
      { language: "cs", label: "Návody" },
      { language: "en", label: "" },
    ],
    createTemplate: [
      { language: "cs", label: "Vytvořít novou šablonu" },
      { language: "en", label: "" },
    ],
    updateTemplate: [
      { language: "cs", label: "Upravit existujicí šablonu" },
      { language: "en", label: "" },
    ],
    selectTemplate: [
      { language: "cs", label: "Vyberte šablonu" },
      { language: "en", label: "" },
    ],
    closeModal: [
      { language: "cs", label: "Zavřit" },
      { language: "en", label: "" },
    ],
    name: [
      { language: "cs", label: "Název šablony" },
      { language: "en", label: "" },
    ],
    uploadThumbnail: [
      { language: "cs", label: "Nahrat obrázek šablony" },
      { language: "en", label: "" },
    ],
    thumbnail: [
      { language: "cs", label: "Obrázek šablony" },
      { language: "en", label: "" },
    ],
    uploadImages: [
      { language: "cs", label: "Nahrat obrázky" },
      { language: "en", label: "" },
    ],
    isGuide: [
      { language: "cs", label: "Je návod" },
      { language: "en", label: "" },
    ],
    saveTemplate: [
      { language: "cs", label: "Uložit šablonu" },
      { language: "en", label: "" },
    ],
    imagesSending: [
      {
        language: "cs",
        label: "Probíhá nahrávání obrázků...",
      },
      { language: "en", label: "" },
    ],
  },
};

export default labels;
